import moment from 'moment';
import { AudienciaCodigo, PageFilter, Status, TipoCodigo } from './general';

const getPageFilterInitialValues = (pageFilter: string) => {
  let customFilter;
  switch (pageFilter) {
    case PageFilter.Comercial:
      customFilter = {
        listaAudiencia: [AudienciaCodigo.Comercial],
        listaTipo: [TipoCodigo.Expiracao, TipoCodigo.Lembrete],
        listaStatus: [Status.Pendente.toString(), Status.Cancelado.toString(), Status.Finalizado.toString()],
        listaStatusSelecionado: [Status.Pendente.toString()],
      };
      break;
    case PageFilter.Financeiro:
      customFilter = {
        listaAudiencia: [AudienciaCodigo.Financeiro],
        listaTipo: [
          TipoCodigo.Cobranca,
          TipoCodigo.Coleta,
          TipoCodigo.Comissao,
          TipoCodigo.Fechamento,
          TipoCodigo.Lembrete,
          TipoCodigo.MobilizacaoEnviar,
          TipoCodigo.MobilizacaoRetirar,
        ],
        listaStatus: [
          Status.Pendente.toString(),
          Status.PendenteMedicao.toString(),
          Status.EmRevisao.toString(),
          Status.PendenteFaturamento.toString(),
          Status.Cancelado.toString(),
          Status.Finalizado.toString(),
        ],
        listaStatusSelecionado: [
          Status.Pendente.toString(),
          Status.PendenteMedicao.toString(),
          Status.EmRevisao.toString(),
          Status.PendenteFaturamento.toString(),
        ],
      };
      break;
    case PageFilter.Operacional:
      customFilter = {
        listaAudiencia: [AudienciaCodigo.Operacional],
        listaTipo: [
          TipoCodigo.Coleta,
          TipoCodigo.Documetacao,
          TipoCodigo.Expiracao,
          TipoCodigo.Lembrete,
          TipoCodigo.MobilizacaoEnviar,
          TipoCodigo.MobilizacaoRetirar,
        ],
        listaStatus: [
          Status.Pendente.toString(),
          Status.Agendado.toString(),
          Status.Cancelado.toString(),
          Status.Finalizado.toString(),
        ],
        listaStatusSelecionado: [Status.Pendente.toString(), Status.Agendado.toString()],
      };
      break;
    default:
      break;
  }

  customFilter = {
    ...customFilter,
    dataInicial: new Date(moment(new Date()).startOf('week').add(2, 'days').format('yyyy-MM-DD')),
    dataFinal: new Date(moment(new Date()).startOf('week').add(6, 'days').format('yyyy-MM-DD')),
  };
  return customFilter;
};

// eslint-disable-next-line import/prefer-default-export
export { getPageFilterInitialValues };
