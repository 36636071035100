/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, Input, Paper, Select, SimpleGrid } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { ChartArrows, DeviceFloppy, Switch2, User } from 'tabler-icons-react';
import {
  EventBulkUpdateEnum,
  getStatusAction,
  getValidStatuses,
  Status,
  TipoCodigo,
} from '../../../../../business/events/general';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { Permission } from '../../../../../models/core/departments.type';
import { EventSearchResponseType } from '../../../../../models/core/events.type';
import { UserType } from '../../../../../models/core/users.type';
import { validate } from '../../../../../utils/permission.utils';
import SummaryView from '../../EventView/components/SummaryView';

type FormViewData = {
  codigoAcao: string;
  idResponsavel: string;
  codigoEventoStatus: string;
};

type DataViewActionProps = {
  users: UserType[];
  data: EventSearchResponseType[];
  selectedData: {
    idEvento: number;
    idContrato: number | null;
    idEntidade: number | null;
    idProposta: number | null;
    idEventoVeiculoCompartilhado: number | null;
    codigoEventoStatus: Status;
    codigoEventoTipo: TipoCodigo;
    data: Date;
    observacao: string | null;
  }[];
  confirmActionResults(
    items: EventSearchResponseType[],
    action: string,
    value: string | null,
    confirmed: boolean
  ): void;
};

export default function DataViewBulkActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const form = useForm<FormViewData>({
    initialValues: {
      codigoAcao: '',
      idResponsavel: '',
      codigoEventoStatus: '',
    },
    validate: {
      codigoAcao: (value) => {
        if (!value || value === '') {
          return 'Campo obrigatório';
        }
        if (getActionList().find((x) => x.value === value)?.disabled) {
          return 'Ação não permitida';
        }
        if (
          props.selectedData
            .map((x) => x.idEvento)
            .filter((y) => !props.data.map((z) => z.idEvento).includes(y)).length > 0
        ) {
          return 'Item(ns) selecionado(s) inválido(s)';
        }
        return null;
      },
      codigoEventoStatus: (value, values: FormViewData) => {
        if (values.codigoAcao === EventBulkUpdateEnum.Status && (!value || value === '')) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const getStatusList = () => {
    const statuses = Array.from(new Set(props.selectedData.map((x) => x.codigoEventoStatus)));
    if (statuses.length !== 1) {
      form.values.codigoEventoStatus = '';
      return [];
    }

    const types = Array.from(new Set(props.selectedData.map((x) => x.codigoEventoTipo)));
    if (types.length !== 1) {
      form.values.codigoEventoStatus = '';
      return [];
    }

    return getValidStatuses(types[0], statuses[0]).map((x) => {
      return {
        label: getStatusAction(x).action,
        value: x,
      };
    });
  };
  const eventStatusList = getStatusList();

  const getActionList = () => {
    return [
      { label: 'Excluir', value: EventBulkUpdateEnum.Excluir, permission: Permission.EventDelete },
      {
        label: 'Responsável',
        value: EventBulkUpdateEnum.Responsavel,
        disabled:
          props.selectedData.filter(
            (x) => x.codigoEventoStatus === Status.Cancelado || x.codigoEventoStatus === Status.Finalizado
          ).length > 0,
        permission: Permission.EventEdit,
      },
      { label: 'Resumo', value: EventBulkUpdateEnum.Resumo, permission: Permission.EventView },
      {
        label: 'Status',
        value: EventBulkUpdateEnum.Status,
        disabled: eventStatusList.length === 0,
        permission: Permission.EventEdit,
      },
      // {
      //   label: 'Programação',
      //   value: EventBulkUpdateEnum.Programacao,
      //   disabled: props.selectedData.filter((x) => x.codigoEventoTipo !== TipoCodigo.Coleta).length > 0,
      //   permission: Permission.EventView,
      // },
    ].filter((x) => validate(x.permission, currentUser.permissoes));
  };

  const handleSubmit = () => {
    let acao;
    let titulo;
    let valor;

    if (form.values.codigoAcao === EventBulkUpdateEnum.Excluir) {
      acao = 'Excluir';
      titulo = 'Responsável';
      valor = props.users.find((x) => x.idUsuario === form.values.idResponsavel)?.nomeCompleto;
    } else if (form.values.codigoAcao === EventBulkUpdateEnum.Responsavel) {
      acao = 'Definir Status';
      titulo = 'Status';
      valor = eventStatusList.find((x) => x.value === form.values.codigoEventoStatus)?.label;
    } else if (form.values.codigoAcao === EventBulkUpdateEnum.Resumo) {
      modals.openModal({
        title: `Evento - Resumos`,
        size: '100%',
        closeOnClickOutside: false,
        children: <SummaryView data={props.selectedData.map((x) => x.idEvento)} event={null} />,
      });
      return;
    } else if (form.values.codigoAcao === EventBulkUpdateEnum.Status) {
      acao = 'Definir Status';
      titulo = 'Status';
      valor = eventStatusList.find((x) => x.value === form.values.codigoEventoStatus)?.label;
    }
    // else if (form.values.codigoAcao === EventBulkUpdateEnum.Programacao) {
    //   modals.openModal({
    //     title: `Evento - Programação`,
    //     size: '80%',
    //     closeOnClickOutside: false,
    //     children: <Schedule data={props.selectedData} />,
    //   });
    //   return;
    // }
    else {
      return;
    }

    modals.openConfirmModal({
      title: `Gostaria de efetuar a ação em massa abaixo para esse(s) ${props.selectedData.length} evento(s)?`,
      size: 'lg',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={2}>
            <PageViewProperty label="Ação" text={acao} size="sm" />
            {form.values.codigoAcao !== EventBulkUpdateEnum.Excluir && (
              <PageViewProperty label={titulo} text={valor || '-'} size="sm" />
            )}
          </SimpleGrid>
        </Paper>
      ),
      onConfirm: () => {
        const finalAction = `bulk-${form.values.codigoAcao}`;
        const finalValue =
          form.values.codigoAcao === EventBulkUpdateEnum.Responsavel
            ? form.values.idResponsavel || null
            : form.values.codigoEventoStatus;

        props.confirmActionResults(
          props.selectedData.map((x) => {
            return JSON.parse(JSON.stringify(x));
          }),
          finalAction,
          finalValue,
          true
        );
      },
    });
  };

  return (
    <form id="event-bulk-actions" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Group>
          <div>
            <Input.Wrapper label="Ações em massa">
              <div />
            </Input.Wrapper>
            <Group>
              <Select
                icon={<ChartArrows size={15} />}
                placeholder="Selecione..."
                data={getActionList()}
                disabled={props.selectedData.length === 0}
                {...form.getInputProps('codigoAcao')}
              />
              {form.values.codigoAcao === EventBulkUpdateEnum.Responsavel && (
                <Select
                  icon={<User size={15} />}
                  placeholder="Selecione..."
                  data={props.users.map((x) => {
                    return {
                      label:
                        x.idUsuario === currentUser.idUsuario ? `${x.nomeCompleto} (eu)` : x.nomeCompleto,
                      value: x.idUsuario,
                      group: x.empresa,
                    };
                  })}
                  onSelect={() => {
                    form.values.codigoEventoStatus = '';
                  }}
                  clearable
                  searchable
                  disabled={props.selectedData.length === 0 || !form.isValid()}
                  {...form.getInputProps('idResponsavel')}
                />
              )}
              {form.values.codigoAcao === EventBulkUpdateEnum.Status && (
                <Select
                  icon={<Switch2 size={15} />}
                  placeholder="Selecione..."
                  data={eventStatusList}
                  onSelect={() => {
                    form.values.idResponsavel = '';
                  }}
                  disabled={props.selectedData.length === 0 || eventStatusList.length === 0}
                  required
                  {...form.getInputProps('codigoEventoStatus')}
                />
              )}
              <Button
                leftIcon={<DeviceFloppy size={18} />}
                disabled={props.selectedData.length === 0 || !form.isValid()}
                type="submit"
              >
                Aplicar
              </Button>
            </Group>
          </div>
        </Group>
      </Paper>
    </form>
  );
}
