/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  Badge,
  Button,
  Chip,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  Space,
  Switch,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
// eslint-disable-next-line import/no-unresolved
import { FormValidationResult } from '@mantine/form/lib/types';
import { forwardRef, ReactNode, useImperativeHandle, useMemo, useState } from 'react';
import { Calculator, Calendar, ClearAll } from 'tabler-icons-react';
import { Action } from '../../../models/core/core.type';
import theme from '../../../theme';
import { RecurrenceData } from '../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../utils/formatter.utils';
import { dateDiffInDaysDisregardTime, tryGetDateISOFormat } from '../../../utils/helper.utils';
import { simulate } from '../../../utils/moment-recur.utils';
import PageSection from '../PageSection/PageSection';

type PageViewPropertyProps = {
  idPropostaRecorrencia: number | null;
  title: string;
  description: string | null;
  color: string;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  dataInicial: Date | null;
  dataFinal: Date | null;
  quantidade: number | null;
  cada: number | null;
  frequencia: string | null;
  ocorrencia: number | null;
  diasSemana: number[] | null;
  diasMes: number[] | null;
  mesAno: number | null;
  disabled: boolean;
};

type FormViewData = {
  dataInicial: Date;
  dataFinal: Date;
  cadaFlag: boolean;
  quantidade: number;
  cada: number;
  frequencia: string;
  ocorrenciaFlag: boolean;
  ocorrencia: number;
  chips: string;
  chipsDiasSemana: string;
  chipsDiasMes: string;
  diasSemana: string[];
  diasMes: string[];
  mesAno: string;
};

type FormResponseData = {
  action: Action;
  dataInicial: Date;
  dataFinal: Date;
  quantidade: number | null;
  cada: number | null;
  frequencia: string | null;
  ocorrencia: number | null;
  diasSemana: number[] | null;
  diasMes: number[] | null;
  mesAno: number | null;
};

const RecurrenceForm = forwardRef((props: PageViewPropertyProps, ref) => {
  const maxSimulationRecords = 50;
  const [simulationData, setSimulationData] = useState<{ data: string; quantity: number }[]>([]);

  const form = useForm<FormViewData>({
    initialValues: {
      dataInicial: props.dataInicial
        ? formatDateStringToDate(tryGetDateISOFormat(props.dataInicial))
        : props.minDate || new Date(),

      dataFinal: props.dataFinal
        ? formatDateStringToDate(tryGetDateISOFormat(props.dataFinal))
        : props.maxDate || new Date(),

      cadaFlag: !!props.cada,
      quantidade: props.quantidade || 1,
      cada: props.cada || 1,
      frequencia: props.frequencia || 'd',

      ocorrenciaFlag: !!props.ocorrencia,
      ocorrencia: props.ocorrencia || 1,

      chips: '',
      chipsDiasSemana: '',
      chipsDiasMes: '',
      diasSemana: props.diasSemana?.map((x) => String(x)) || [new Date().getDay().toString()],
      diasMes: props.diasMes?.map((x) => String(x)) || [new Date().getDate().toString()],
      mesAno: props.mesAno?.toString() || new Date().getMonth().toString(),
    },
    validate: {
      dataInicial: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (
          dateDiffInDaysDisregardTime(value, props.minDate || value, true) < 0 ||
          dateDiffInDaysDisregardTime(value, props.maxDate || value, true) > 0
        ) {
          return `Data fora do limite (${formatDateToString(props.minDate || value)} - ${formatDateToString(
            props.maxDate || value
          )})`;
        }
        return null;
      },
      dataFinal: (value, values: FormViewData) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (value < values.dataInicial) {
          return 'Data final não pode ser anterior à data inicial';
        }
        if (
          dateDiffInDaysDisregardTime(value, props.minDate || value, true) < 0 ||
          dateDiffInDaysDisregardTime(value, props.maxDate || value, true) > 0
        ) {
          return `Data fora do limite (${formatDateToString(props.minDate || value)} - ${formatDateToString(
            props.maxDate || value
          )})`;
        }
        return null;
      },
      quantidade: (value, values: FormViewData) => {
        if (!value && values.cadaFlag) {
          return 'Campo obrigatório';
        }
        return null;
      },
      cada: (value, values: FormViewData) => {
        if (!value && values.cadaFlag) {
          return 'Campo obrigatório';
        }
        return null;
      },
      ocorrencia: (value, values: FormViewData) => {
        if (!value && values.cadaFlag && values.ocorrenciaFlag) {
          return 'Campo obrigatório';
        }
        return null;
      },
      diasSemana: (value, values: FormViewData) => {
        if (
          value.length === 0 &&
          values.cadaFlag &&
          (values.frequencia === 'd' || values.frequencia === 'w')
        ) {
          return 'Ao menos um dia deve ser selecionado';
        }
        return null;
      },
      diasMes: (value, values: FormViewData) => {
        if (value.length === 0 && values.cadaFlag && values.frequencia === 'm') {
          return 'Ao menos um dia deve ser selecionado';
        }
        return null;
      },
    },
  });

  const validateForm = (): FormValidationResult | null => {
    const formResult = form.validate();
    if (formResult.hasErrors) {
      if (formResult.errors.diasSemana || formResult.errors.diasMes) {
        form.setFieldError(
          formResult.errors.diasSemana ? 'chipsDiasSemana' : 'chipsDiasMes',
          formResult.errors.diasSemana || formResult.errors.diasMes
        );
      }
      return null;
    }
    return formResult;
  };

  const isModified = (transformedForm: FormResponseData): boolean => {
    const origData = `${formatDateToString(props.dataInicial || new Date()) || ''}
    |${formatDateToString(props.dataFinal || new Date()) || ''}
    |${props.quantidade || ''}
    |${props.cada || ''}
    |${props.frequencia || ''}
    |${props.ocorrencia || ''}
    |${props.diasSemana || ''}
    |${props.diasMes || ''}
    |${props.mesAno || ''}`;

    const formData = `${formatDateToString(transformedForm.dataInicial) || ''}
    |${formatDateToString(transformedForm.dataFinal) || ''}
    |${transformedForm.quantidade || ''}
    |${transformedForm.cada || ''}
    |${transformedForm.frequencia || ''}
    |${transformedForm.ocorrencia || ''}
    |${transformedForm.diasSemana || ''}
    |${transformedForm.diasMes || ''}
    |${transformedForm.mesAno || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): FormResponseData | null {
      if (validateForm() === null) {
        throw Error('Existem pendências a serem corrigidas.');
      }

      const response: FormResponseData = {
        action: props.idPropostaRecorrencia ? Action.Nothing : Action.Add,
        dataInicial: form.values.dataInicial,
        dataFinal: form.values.dataFinal,
        quantidade: form.values.quantidade,
        cada: form.values.cada,
        frequencia: form.values.frequencia,
        ocorrencia: form.values.ocorrencia,
        diasSemana: form.values.diasSemana.map((x) => Number(x)),
        diasMes: form.values.diasMes.map((x) => Number(x)),
        mesAno: Number(form.values.mesAno),
      };

      if (!form.values.cadaFlag) {
        response.quantidade = null;
        response.cada = null;
        response.frequencia = null;
        response.ocorrencia = null;
        response.diasSemana = null;
        response.diasMes = null;
        response.mesAno = null;
      }
      if (!form.values.ocorrenciaFlag) {
        response.ocorrencia = null;
      }
      if (form.values.frequencia === 'd' || form.values.frequencia === 'w') {
        response.diasMes = null;
        response.mesAno = null;
      }
      if (form.values.frequencia === 'm') {
        response.diasSemana = null;
        response.mesAno = null;
      }
      if (form.values.frequencia === 'y') {
        response.diasSemana = null;
        response.diasMes = null;
      }

      if (props.idPropostaRecorrencia && isModified(response)) {
        response.action = Action.Modify;
      }

      return response;
    },
    clear() {
      form.reset();
    },
  }));

  const buildMonthDayChips = useMemo(() => {
    const days = RecurrenceData.Days;
    const months = RecurrenceData.Months;

    const tipo = form.values.frequencia;
    const chips: ReactNode[] = [];

    switch (tipo) {
      case 'd':
      case 'w':
        for (let i = 0; i < days.length; i++) {
          chips.push(
            <Chip key={i} value={i.toString()} disabled={props.disabled}>
              {days[i]}
            </Chip>
          );
        }
        break;
      case 'm':
        for (let i = 1; i < 32; i++) {
          chips.push(
            <Chip key={i} value={i.toString()} disabled={props.disabled}>
              {i}
            </Chip>
          );
        }
        break;
      case 'y':
        for (let i = 0; i < months.length; i++) {
          chips.push(
            <Chip key={i} value={i.toString()} disabled={props.disabled}>
              {months[i]}
            </Chip>
          );
        }
        break;
      default:
        break;
    }

    return chips;
  }, [form.values.frequencia, props.disabled]);

  const buildBadges = useMemo(() => {
    if ((simulationData || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {simulationData.map((x, i) => (
          <Group spacing="xs">
            <Badge key={i.toString()} variant="outline" size="md">
              {x.data}
            </Badge>
            <Badge
              key={`${i.toString()}-${x.quantity.toString()}`}
              variant="filled"
              size="xs"
              style={{ marginLeft: -10 }}
            >
              {x.quantity}
            </Badge>
          </Group>
        ))}
      </Group>
    );
  }, [simulationData]);

  const simulateData = () => {
    setSimulationData([]);

    if (!validateForm()) {
      return;
    }

    let data: { data: string; quantity: number }[] = [];
    if (form.values.cadaFlag) {
      data = simulate(
        JSON.parse(
          JSON.stringify({
            frequencia: form.values.frequencia,
            dataInicial: form.values.dataInicial,
            dataFinal: form.values.dataFinal,
            quantidade: form.values.quantidade,
            cada: form.values.cada,
            diasMes: form.values.diasMes.map((x) => Number(x)),
            diasSemana: form.values.diasSemana.map((x) => Number(x)),
            mesAno: Number(form.values.mesAno),
            ocorrencia: form.values.ocorrenciaFlag ? form.values.ocorrencia : maxSimulationRecords,
          })
        )
      );
    }

    setSimulationData(data);
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={props.color} label={props.title} text={props.description || ''} />
        <Space h="xs" />
        <form id="recurrence" noValidate>
          <Grid columns={12}>
            <Grid.Col span={2}>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                label="Data Inicial"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={props.minDate}
                maxDate={props.maxDate}
                required
                disabled={props.disabled}
                {...form.getInputProps('dataInicial')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                label="Data Final"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={form.values.dataInicial}
                maxDate={props.maxDate}
                required
                disabled={props.disabled}
                {...form.getInputProps('dataFinal')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <div>
                <Input.Wrapper label="Repetir quantas vezes a cada?" required>
                  <div />
                </Input.Wrapper>
                <Group>
                  <Switch
                    {...form.getInputProps('cadaFlag', { type: 'checkbox' })}
                    disabled={props.disabled}
                  />
                  <Group spacing="xs">
                    <NumberInput
                      style={{ width: 85 }}
                      min={1}
                      defaultValue={1}
                      step={1}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      disabled={!form.values.cadaFlag || props.disabled}
                      required
                      {...form.getInputProps('quantidade')}
                    />
                    <NumberInput
                      style={{ width: 85 }}
                      min={1}
                      defaultValue={1}
                      step={1}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      disabled={!form.values.cadaFlag || props.disabled}
                      required
                      {...form.getInputProps('cada')}
                    />
                    <Select
                      style={{ width: 120 }}
                      placeholder="Selecione..."
                      data={RecurrenceData.Frequency}
                      {...form.getInputProps('frequencia')}
                      disabled={!form.values.cadaFlag || props.disabled}
                      required
                    />
                  </Group>
                </Group>
              </div>
            </Grid.Col>
            <Grid.Col span={4}>
              <div>
                <Input.Wrapper label="Encerrar após ocorrências?" required>
                  <div />
                </Input.Wrapper>
                <Group>
                  <Switch
                    {...form.getInputProps('ocorrenciaFlag', { type: 'checkbox' })}
                    disabled={!form.values.cadaFlag || props.disabled}
                  />
                  <NumberInput
                    style={{ width: 85 }}
                    placeholder="Ocorrências"
                    min={1}
                    defaultValue={1}
                    step={1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    disabled={!form.values.ocorrenciaFlag || !form.values.cadaFlag || props.disabled}
                    required
                    {...form.getInputProps('ocorrencia')}
                  />
                </Group>
              </div>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          {form.values.cadaFlag && (
            <div>
              <Paper shadow="xs" p="md" withBorder>
                <Grid columns={1}>
                  <Grid.Col span={1}>
                    <Input.Wrapper
                      {...form.getInputProps(
                        form.values.frequencia === 'd' || form.values.frequencia === 'w'
                          ? 'chipsDiasSemana'
                          : form.values.frequencia === 'm'
                          ? 'chipsDiasMes'
                          : 'chips'
                      )}
                    >
                      <Chip.Group
                        multiple={form.values.frequencia !== 'y'}
                        position="center"
                        {...form.getInputProps(
                          form.values.frequencia === 'd' || form.values.frequencia === 'w'
                            ? 'diasSemana'
                            : form.values.frequencia === 'm'
                            ? 'diasMes'
                            : 'mesAno'
                        )}
                      >
                        {buildMonthDayChips}
                      </Chip.Group>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </Paper>
            </div>
          )}
        </form>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={theme?.colors?.accent?.[6] || ''}
            label="Simulação"
            text={`Até ${maxSimulationRecords} registros de data simulando a configuração acima.`}
          />
          <Group>
            <Button
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              onClick={() => {
                setSimulationData([]);
              }}
            >
              Limpar
            </Button>
            <Button
              color="accent"
              leftIcon={<Calculator size={18} />}
              onClick={simulateData}
              // disabled={props.disabled}
            >
              Calcular
            </Button>
          </Group>
        </Group>
        <Space h="xs" />
        <Paper shadow="xs" p="md" withBorder>
          {buildBadges}
        </Paper>
      </Paper>
    </div>
  );
});

export default RecurrenceForm;
