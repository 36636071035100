/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { Button, Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { duplicar } from '../../../../business/proposals/duplicate';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { CompanyType, EntityTypeType, ProposalStatusType } from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';
import { ProposalInsertType, ProposalSearchResponseType } from '../../../../models/core/proposals.type';
import proposalsService from '../../../../services/core/proposals.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import DataView from './components/DataView';
import { Filter, FilterData } from './components/Filter';

function ProposalSearch() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
    entityTypeData: EntityTypeType[];
    proposalStatusData: ProposalStatusType[];
  }>({ companyData: [], entityTypeData: [], proposalStatusData: [] });

  const [data, setData] = useState<ProposalSearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      setFilterData({
        companyData: await cacheUtils.listCompanies(),
        entityTypeData: await cacheUtils.listEntityTypes(),
        proposalStatusData: await cacheUtils.listProposalStatuses(),
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.ProposalSearch) || '[]'
      ) as ProposalSearchResponseType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        filter({
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
          entidadeData: null,
          idEntidade: null,
          entidade: '',
          listaStatus: [],
        });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.ProposalSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      inputFilterData.entidadeData = null;

      const result = await proposalsService.search({
        ...inputFilterData,
        idCliente: inputFilterData.idEntidade,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.ProposalSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Propostas',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar propostas.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (
    item: ProposalSearchResponseType,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    try {
      let tempData;
      let completedAction;
      setLoading(true);

      switch (action) {
        case 'excluir':
          await proposalsService.delete({ idProposta: item.idProposta });
          setData(data.filter((x) => x.idProposta !== item.idProposta));
          completedAction = 'excluída';
          break;
        case 'duplicar':
          tempData = await proposalsService.select({ idProposta: item.idProposta });
          tempData = await proposalsService.insert(duplicar(tempData, false) as ProposalInsertType);
          completedAction = 'duplicada';
          navigate(`${Feature.Home.Proposal.link}/${tempData.idProposta}`);
          break;
        case 'reajustar':
          tempData = await proposalsService.select({ idProposta: item.idProposta });
          tempData = await proposalsService.insert(duplicar(tempData, true) as ProposalInsertType);
          completedAction = 'duplicada para reajuste';
          navigate(`${Feature.Home.Proposal.link}/${tempData.idProposta}`);
          break;
        case 'visualizar':
          navigate(`${Feature.Home.Proposal.link}/${item.idProposta}`);
          return;
        case 'editar':
          navigate(`${Feature.Home.Proposal.link}/${item.idProposta}/edit`);
          return;
        case 'imprimir':
          window.open(`${Feature.Home.Proposal.link}/${item.idProposta}/print`, '_blank');
          completedAction = 'enviada para impressão';
          return;
        default:
          break;
      }

      showNotification({
        title: 'Propostas',
        message: `Proposta ${completedAction} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Propostas',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} a proposta.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Proposal}
        title="Propostas"
        description="Gerencie propostas."
        buttons={[
          <Button
            key="Adicionar"
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={() => navigate('/proposals/add')}
            data-permission={Permission.ProposalAdd}
          >
            Adicionar
          </Button>,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter
            companies={filterData.companyData}
            entityTypeData={filterData.entityTypeData}
            proposalStatuses={filterData.proposalStatusData}
            filter={filter}
            clear={clear}
            loading={loading}
          />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default ProposalSearch;
