/* eslint-disable no-nested-ternary */
import { ProposalRecurrenceType } from '../models/core/proposals.type';
import { RecurrenceData } from './constants.utils';

function montarRecorrenciaString(
  recorrencia: ProposalRecurrenceType
): { str1: string; str2: string | null } | null {
  if (!recorrencia.cada) {
    return null;
  }
  const frequencia = RecurrenceData.Frequency.find((x) => x.value === recorrencia.frequencia)?.label;

  const dias = recorrencia.diasSemana
    ? recorrencia.diasSemana
        .sort((a, b) => {
          const aValue = a;
          const bValue = b;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        })
        .map((x) => RecurrenceData.Days[x])
    : recorrencia.diasMes
    ? recorrencia.diasMes.sort((a, b) => {
        const aValue = a;
        const bValue = b;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      })
    : null;

  return {
    str1: `${recorrencia.quantidade ?? 1}x a cada ${recorrencia.cada} ${frequencia}${
      recorrencia.ocorrencia ? ` por ${recorrencia.ocorrencia} vez(es)` : ''
    }`,
    str2: dias
      ? `Dia(s): ${dias ? dias.join(', ') : '-'}`
      : recorrencia.mesAno
      ? `Mês: ${recorrencia.mesAno ? RecurrenceData.Months[recorrencia.mesAno] : null}`
      : null,
  };
}

// eslint-disable-next-line import/prefer-default-export
export { montarRecorrenciaString };
