import { Relacao } from '../../business/events/general';
import {
  EventBasicSearchRequestType,
  EventBasicSearchResponseType,
  EventBillingType,
  EventBulkUpdateType,
  EventDeleteType,
  EventDocumentType,
  EventInsertType,
  EventMeasurementSearchRequestType,
  EventMeasurementSearchResponseType,
  EventMeasurementType,
  EventRelationType,
  EventSearchRequestType,
  EventSearchResponseType,
  EventSelectType,
  EventSharedResidueType,
  EventStatusUpdateType,
  EventType,
  EventUpdateType,
} from '../../models/core/events.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { call } from '../core.service';

const eventsEndpoint = '/v1/eventos';

export default class EventsService {
  static search = async (searchProps: EventSearchRequestType): Promise<EventSearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: eventsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static searchBasic = async (
    searchProps: EventBasicSearchRequestType
  ): Promise<EventBasicSearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${eventsEndpoint}/basico`,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<EventSearchResponseType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: EventSelectType): Promise<EventType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${eventsEndpoint}/${selectProps.idEvento}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: EventUpdateType): Promise<EventType> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${eventsEndpoint}/${updateProps.idEvento}`,
      data: updateProps,
    };
    return (await call(request))?.data?.data;
  };

  static delete = async (deleteProps: EventDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${eventsEndpoint}/${deleteProps.idEvento}`,
    };
    return call(request);
  };

  static insert = async (insertProps: EventInsertType): Promise<EventType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: eventsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };

  static updateStatus = async (updateStatusProps: EventStatusUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${eventsEndpoint}/${updateStatusProps.idEvento}/status`,
      data: updateStatusProps,
    };
    return call(request);
  };

  static bulkUpdate = async (bulkUpdateProps: EventBulkUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${eventsEndpoint}/bulk`,
      data: bulkUpdateProps,
    };
    return call(request);
  };

  static insertDocument = async (insertDocumentProps: EventDocumentType): Promise<EventDocumentType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${eventsEndpoint}/${insertDocumentProps.idEvento}/documentos`,
      data: insertDocumentProps,
    };
    return (await call(request))?.data?.data;
  };

  static updateDocument = async (updateDocumentProps: EventDocumentType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${eventsEndpoint}/${updateDocumentProps.idEvento}/documentos/${updateDocumentProps.idEventoDocumento}`,
      data: updateDocumentProps,
    };
    return call(request);
  };

  static deleteDocument = async (deleteDocumentProps: {
    idEvento: number;
    idEventoDocumento: number;
  }): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${eventsEndpoint}/${deleteDocumentProps.idEvento}/documentos/${deleteDocumentProps.idEventoDocumento}`,
    };
    return call(request);
  };

  static insertMeasurement = async (
    insertMeasurementProps: EventMeasurementType
  ): Promise<EventMeasurementType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${eventsEndpoint}/${insertMeasurementProps.idEvento}/medicao`,
      data: insertMeasurementProps,
    };
    return (await call(request))?.data?.data;
  };

  static updateMeasurement = async (
    updateMeasurementProps: EventMeasurementType
  ): Promise<EventMeasurementType> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${eventsEndpoint}/${updateMeasurementProps.idEvento}/medicao/${updateMeasurementProps.idEventoMedicao}`,
      data: updateMeasurementProps,
    };
    return (await call(request))?.data?.data;
  };

  static insertBilling = async (insertBillingProps: EventBillingType): Promise<EventBillingType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${eventsEndpoint}/${insertBillingProps.idEvento}/faturamento`,
      data: insertBillingProps,
    };
    return (await call(request))?.data?.data;
  };

  static updateBilling = async (updateBillingProps: EventBillingType): Promise<EventBillingType> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${eventsEndpoint}/${updateBillingProps.idEvento}/faturamento/${updateBillingProps.idEventoFaturamento}`,
      data: updateBillingProps,
    };
    return (await call(request))?.data?.data;
  };

  static insertRelation = async (insertRelationProps: {
    idEvento: number;
    relacoes: { idEventoReferenciado: number; codigoEventoRelacao: Relacao }[];
  }): Promise<EventRelationType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${eventsEndpoint}/${insertRelationProps.idEvento}/relacoes`,
      data: insertRelationProps,
    };
    return (await call(request))?.data?.data;
  };

  static deleteRelation = async (deleteRelationProps: {
    idEvento: number;
    relacoes: number[];
  }): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${eventsEndpoint}/${deleteRelationProps.idEvento}/relacoes`,
      data: deleteRelationProps,
    };
    return call(request);
  };

  static searchMeasurement = async (
    searchProps: EventMeasurementSearchRequestType
  ): Promise<EventMeasurementSearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${eventsEndpoint}/fechamento`,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static searchSharedResidues = async (searchProps: {
    idPropostaResiduoPlano: number;
  }): Promise<EventSharedResidueType | null> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${eventsEndpoint}/compartilhamento/${searchProps.idPropostaResiduoPlano}`,
    };
    return (await call(request))?.data?.data;
  };
}
