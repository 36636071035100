/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Button, Group, LoadingOverlay, Paper, Space, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { DeviceFloppy, ExternalLink } from 'tabler-icons-react';
import { Relacao } from '../../../business/events/general';
import { CompanyType, DocumentType, EventStatusType } from '../../../models/core/cache.type';
import { EventRelationType, EventSearchResponseType } from '../../../models/core/events.type';
import { UserType } from '../../../models/core/users.type';
import eventsService from '../../../services/core/events.service';
import theme from '../../../theme';
import { formatDateToString } from '../../../utils/formatter.utils';
import ProfileCardLink from '../ProfileCardLink/ProfileCardLink';
import { EventSearchFilter, FilterData } from './EventSearchFilter';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';
import { newGuid } from '../../../utils/helper.utils';

type FormViewProps = {
  referenceData: {
    companyData: CompanyType[];
    eventStatusData: EventStatusType[];
    userData: UserType[];
    documentTypeData: DocumentType[];
  };
  idsToBeDisabled: number[];
  filterData: {
    idContrato: number | undefined;
    idEntidade: number | undefined;
    idProposta: number | undefined;
    dataInicial: Date | undefined;
    dataFinal: Date | undefined;
    listaAudiencia: string[];
    listaTipo: string[];
    listaStatus: string[];
    codigoEventoRelacao: Relacao;
  } | null;
  save: {
    idEvento: number;
    codigoEventoRelacao: Relacao;
  } | null;
  callback(items: EventSearchResponseType[] | EventRelationType[], confirmed: boolean): void;
};

export default function EventSearch(props: FormViewProps) {
  const [data, setData] = useState<EventSearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedData, setSelectedData] = useState<EventSearchResponseType[]>([]);

  const handleSubmit = async () => {
    if (!props.save) {
      props.callback(selectedData, true);
      return;
    }

    let response;
    try {
      setSaving(true);

      response = await eventsService.insertRelation({
        idEvento: props.save!.idEvento,
        relacoes: selectedData.map((x) => {
          return { idEventoReferenciado: x.idEvento, codigoEventoRelacao: props.save!.codigoEventoRelacao };
        }),
      });

      showNotification({
        title: `Evento - Visualizar`,
        message: `Relação(ões) adicionada(s) com sucesso.`,
        color: 'green',
      });

      props.callback(response, true);
    } catch (exception: any) {
      showNotification({
        title: `Evento - Visualizar`,
        message: exception?.isBusinessException
          ? exception.description
          : 'Não foi possível adicionar a(s) relação(ões) ao evento.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  const clear = () => {
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    const defaultLimit = 100;

    try {
      setLoading(true);

      let result = await eventsService.search({
        ...inputFilterData,
        idContrato: inputFilterData.idContrato?.toString() || null,
        idEntidade: inputFilterData.idEntidade?.toString() || null,
        idProposta: inputFilterData.idProposta?.toString() || null,
        dataInicial: inputFilterData.dataInicial
          ? moment(inputFilterData.dataInicial).format('yyyy-MM-DD')
          : null,
        dataFinal: inputFilterData.dataFinal ? moment(inputFilterData.dataFinal).format('yyyy-MM-DD') : null,
        limit: defaultLimit,
        offset: 0,
      });

      if (props.filterData?.codigoEventoRelacao === Relacao.VeiculoCompartilhado) {
        result = result.filter((x) => !x.idEventoVeiculoCompartilhado);
      }

      clear();
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Eventos',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar eventos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EventSearchResponseType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: EventSearchResponseType) => ({
      disabled: props.idsToBeDisabled.includes(record.idEvento),
      name: record.idEvento.toString(),
    }),
  };

  return (
    <div>
      <EventSearchFilter
        companies={props.referenceData.companyData}
        eventStatusData={props.referenceData.eventStatusData}
        users={props.referenceData.userData}
        documents={props.referenceData.documentTypeData}
        filter={filter}
        clear={clear}
        loading={loading}
        filterData={props.filterData}
      />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: '#',
                key: 'idEvento',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
                  const aValue = a.idEvento;
                  const bValue = b.idEvento;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventSearchResponseType) => row.idEvento,
              },
              {
                title: 'Empresa',
                key: 'empresa',
                dataIndex: 'empresa',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
                  a.empresa.localeCompare(b.empresa),
              },

              {
                title: 'Audiência',
                key: 'eventoAudiencia',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
                  a.eventoAudiencia.localeCompare(b.eventoAudiencia),
                render: (row: EventSearchResponseType) => (
                  <Badge variant="outline">{row.eventoAudiencia}</Badge>
                ),
              },
              {
                title: 'Tipo',
                key: 'eventoTipo',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
                  a.eventoTipo.localeCompare(b.eventoTipo),
                render: (row: EventSearchResponseType) => <Badge variant="outline">{row.eventoTipo}</Badge>,
              },
              {
                title: 'Fonte',
                key: 'fonte',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
                  const aValue = a.idContrato
                    ? `C${a.idContrato}`
                    : a.idEntidade
                    ? `E${a.idEntidade}`
                    : a.idProposta
                    ? `P${a.idProposta}`
                    : '-';
                  const bValue = b.idContrato
                    ? `C${b.idContrato}`
                    : b.idEntidade
                    ? `E${b.idEntidade}`
                    : b.idProposta
                    ? `P${b.idProposta}`
                    : '-';
                  return aValue.localeCompare(bValue);
                },
                render: (row: EventSearchResponseType) => {
                  if (row.idContrato) {
                    return (
                      <ProfileCardLink
                        id={row.idContrato.toString()}
                        name="Contrato"
                        nameSize="sm"
                        description={`# ${row.idContrato}`}
                        descriptionSize="xs"
                        linkPrefix="contracts"
                        showLink
                      />
                    );
                  }
                  if (row.idEntidade) {
                    return (
                      <ProfileCardLink
                        id={row.idEntidade.toString()}
                        name="Entidade"
                        nameSize="sm"
                        description={`# ${row.idEntidade}`}
                        descriptionSize="xs"
                        linkPrefix="entities"
                        showLink
                      />
                    );
                  }
                  if (row.idProposta) {
                    return (
                      <ProfileCardLink
                        id={row.idProposta.toString()}
                        name="Proposta"
                        nameSize="sm"
                        description={`# ${row.idProposta}`}
                        descriptionSize="xs"
                        linkPrefix="proposals"
                        showLink
                      />
                    );
                  }
                  return '-';
                },
              },
              {
                title: 'Cliente/Entidade',
                key: 'clienteEntidade',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
                  const clienteA = a.resumoParcialJSON?.cliente;
                  const entidadeA = a.resumoParcialJSON?.entidade;
                  const aValue =
                    clienteA?.clienteNomeFantasia ||
                    clienteA?.clienteRazaoSocial ||
                    clienteA?.clienteNome ||
                    entidadeA?.nomeFantasia ||
                    entidadeA?.razaoSocial ||
                    entidadeA?.nome ||
                    '-';

                  const clienteB = b.resumoParcialJSON?.cliente;
                  const entidadeB = b.resumoParcialJSON?.entidade;
                  const bValue =
                    clienteB?.clienteNomeFantasia ||
                    clienteB?.clienteRazaoSocial ||
                    clienteB?.clienteNome ||
                    entidadeB?.nomeFantasia ||
                    entidadeB?.razaoSocial ||
                    entidadeB?.nome ||
                    '-';

                  return aValue.localeCompare(bValue);
                },
                render: (row: EventSearchResponseType) => {
                  const { cliente, entidade } = row.resumoParcialJSON || {};
                  const entidadeRender = {
                    id: cliente?.idCliente || entidade?.idEntidade,
                    cnpj: cliente?.clienteCNPJ || entidade?.cnpj,
                    nomePJ:
                      cliente?.clienteNomeFantasia ||
                      cliente?.clienteRazaoSocial ||
                      entidade?.nomeFantasia ||
                      entidade?.razaoSocial,
                    descricaoPJ:
                      cliente?.clienteNomeFantasia || entidade?.nomeFantasia
                        ? cliente?.clienteRazaoSocial || entidade?.razaoSocial
                        : cliente?.clienteCNPJ || entidade?.cnpj,
                    nomePF: cliente?.clienteNome || entidade?.nome,
                    descricaoPF: cliente?.clienteCPF || entidade?.cpf,
                  };
                  if (row.idContrato) {
                    return (
                      <ProfileCardLink
                        id={row.idContrato.toString()}
                        name={entidadeRender.nomePJ || entidadeRender.nomePF || '?'}
                        nameSize="sm"
                        description={entidadeRender.descricaoPJ || entidadeRender.descricaoPF}
                        descriptionSize="xs"
                        linkPrefix="contracts"
                        showLink
                      />
                    );
                  }
                  if (row.idEntidade) {
                    return (
                      <ProfileCardLink
                        id={row.idEntidade.toString()}
                        name={entidadeRender.nomePJ || entidadeRender.nomePF || '?'}
                        nameSize="sm"
                        description={entidadeRender.descricaoPJ || entidadeRender.descricaoPF}
                        descriptionSize="xs"
                        linkPrefix="entities"
                        showLink
                      />
                    );
                  }
                  if (row.idProposta) {
                    return (
                      <ProfileCardLink
                        id={row.idProposta.toString()}
                        name={entidadeRender.nomePJ || entidadeRender.nomePF || '?'}
                        nameSize="sm"
                        description={entidadeRender.descricaoPJ || entidadeRender.descricaoPF}
                        descriptionSize="xs"
                        linkPrefix="proposals"
                        showLink
                      />
                    );
                  }
                  return '-';
                },
              },
              {
                title: 'Descrição',
                key: 'descricao',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
                  const aValue = a.automatico
                    ? a.descricao
                    : a.resumoParcialJSON?.residuo?.residuoCliente ||
                      a.resumoParcialJSON?.servico?.servico ||
                      a.descricao;
                  const bValue = b.automatico
                    ? b.descricao
                    : b.resumoParcialJSON?.residuo?.residuoCliente ||
                      b.resumoParcialJSON?.servico?.servico ||
                      b.descricao;
                  return (aValue || '').localeCompare(bValue || '');
                },
                render: (row: EventSearchResponseType) => {
                  let value = row.automatico
                    ? row.descricao
                    : `${
                        row.resumoParcialJSON?.residuo?.residuoCliente ||
                        row.resumoParcialJSON?.servico?.servico ||
                        '-'
                      } | ${row.descricao}`;
                  if (value.startsWith('- |')) {
                    value = value.replace('- |', '');
                  }
                  return <TableCellEllipsis label={value} numberOfChars={50} toolTipWidth={300} />;
                },
              },
              {
                title: 'Status',
                key: 'status',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
                  a.eventoStatus.localeCompare(b.eventoStatus),
                render: (row: EventSearchResponseType) => <Badge variant="outline">{row.eventoStatus}</Badge>,
              },
              {
                title: 'Data',
                key: 'data',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
                  new Date(a.data).valueOf() - new Date(b.data).valueOf(),
                render: (row: EventSearchResponseType) => formatDateToString(row.data),
              },
              {
                title: 'Documentos',
                key: 'documentos',
                sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
                  const aValue = (a.resumoParcialJSON?.documentos || []).length;
                  const bValue = (b.resumoParcialJSON?.documentos || []).length;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventSearchResponseType) => {
                  const documentos = row.resumoParcialJSON?.documentos || [];
                  if (documentos.length > 0) {
                    return (
                      <Group spacing="xs">
                        {documentos.map((y) => {
                          return (
                            <Badge
                              key={newGuid()}
                              variant="outline"
                            >{`${y.documentoTipo} - ${y.numeroDocumento}`}</Badge>
                          );
                        })}
                      </Group>
                    );
                  }
                  return '-';
                },
              },
              {
                title: 'Ações',
                width: '100px',
                render: (row: EventSearchResponseType) => (
                  <Tooltip withArrow transition="fade" transitionDuration={200} label="Abrir">
                    <ActionIcon
                      size="sm"
                      color="primary"
                      style={{ borderColor: theme?.colors?.primary?.[6] }}
                      variant="outline"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.setAttribute('href', `/events?id=${row.idEvento}`);
                        link.setAttribute('target', '_blank');
                        link.click();
                      }}
                    >
                      <ExternalLink size={15} color={theme?.colors?.primary?.[6]} />
                    </ActionIcon>
                  </Tooltip>
                ),
              },
            ]}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            rowKey={(item: EventSearchResponseType) => item.idEvento}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit} loading={saving}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
