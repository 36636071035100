/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Button, Chip, Grid, Group, Paper, Space } from '@mantine/core';
// eslint-disable-next-line import/no-unresolved
import { forwardRef, ReactNode, useMemo, useState } from 'react';
import { Calculator, ClearAll } from 'tabler-icons-react';
import { ProposalRecurrenceType } from '../../../models/core/proposals.type';
import theme from '../../../theme';
import { formatDateToString } from '../../../utils/formatter.utils';
import { simulate } from '../../../utils/moment-recur.utils';
import PageSection from '../PageSection/PageSection';
import PageViewAudit from '../PageViewAudit/PageViewAudit';
import PageViewProperty from '../PageViewProperty/PageViewProperty';

type PageViewPropertyProps = {
  title: string;
  description: string | null;
  color: string;
  data: ProposalRecurrenceType;
};

const RecurrenceView = forwardRef((props: PageViewPropertyProps, ref) => {
  const maxSimulationRecords = 50;
  const [simulationData, setSimulationData] = useState<{ data: string; quantity: number }[]>([]);

  const buildMonthDayChips = useMemo(() => {
    const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    const tipo = props.data.frequencia;
    const chips: ReactNode[] = [];

    switch (tipo) {
      case 'd':
      case 'w':
        for (let i = 0; i < days.length; i++) {
          chips.push(
            <Chip key={i} value={i.toString()} disabled>
              {days[i]}
            </Chip>
          );
        }
        break;
      case 'm':
        for (let i = 1; i < 32; i++) {
          chips.push(
            <Chip key={i} value={i.toString()} disabled>
              {i}
            </Chip>
          );
        }
        break;
      case 'y':
        for (let i = 0; i < months.length; i++) {
          chips.push(
            <Chip key={i} value={i.toString()} disabled>
              {months[i]}
            </Chip>
          );
        }
        break;
      default:
        break;
    }

    return chips;
  }, [props.data.frequencia]);

  const buildBadges = useMemo(() => {
    if ((simulationData || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {simulationData.map((x, i) => (
          <Badge key={i.toString()} variant="outline" size="md">
            {x.data}
          </Badge>
        ))}
      </Group>
    );
  }, [simulationData]);

  const simulateData = () => {
    setSimulationData([]);
    const data = simulate(props.data);
    setSimulationData(data);
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={props.color} label={props.title} text={props.description || ''} />
        <Space h="xs" />
        <Grid columns={6}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Data Inicial"
              text={props.data.dataInicial ? formatDateToString(props.data.dataInicial) : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Data Final"
              text={props.data.dataFinal ? formatDateToString(props.data.dataFinal) : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Repetir a cada?"
              text={
                props.data.cada
                  ? `${props.data.cada} ${
                      props.data.frequencia === 'd'
                        ? 'dias'
                        : props.data.frequencia === 'w'
                        ? 'semanas'
                        : props.data.frequencia === 'm'
                        ? 'meses'
                        : 'anos'
                    }`
                  : '-'
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Encerrar após ocorrências?"
              text={props.data.ocorrencia || '-'}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        {props.data.cada && (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={1}>
                <Grid.Col span={1}>
                  <Chip.Group
                    multiple={props.data.frequencia !== 'y'}
                    position="center"
                    value={
                      props.data.frequencia === 'd' || props.data.frequencia === 'w'
                        ? props.data.diasSemana?.map((x) => String(x)) || []
                        : props.data.frequencia === 'm'
                        ? props.data.diasMes?.map((x) => String(x)) || []
                        : props.data.mesAno?.toString()
                    }
                  >
                    {buildMonthDayChips}
                  </Chip.Group>
                </Grid.Col>
              </Grid>
            </Paper>
          </div>
        )}
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={theme?.colors?.accent?.[6] || ''}
            label="Simulação"
            text={`Até ${maxSimulationRecords} registros de data simulando a configuração acima.`}
          />
          <Group>
            <Button
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              onClick={() => {
                setSimulationData([]);
              }}
            >
              Limpar
            </Button>
            <Button color="accent" leftIcon={<Calculator size={18} />} onClick={simulateData}>
              Calcular
            </Button>
          </Group>
        </Group>
        <Space h="xs" />
        <Paper shadow="xs" p="md" withBorder>
          {buildBadges}
        </Paper>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data.idModificadoPor || null}
        modificadoPor={props.data.modificadoPor || null}
        dataModificacao={props.data.dataModificacao || null}
        size="sm"
      />
    </div>
  );
});

export default RecurrenceView;
