/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractStatusEnum } from '../../../../business/contracts/status';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { CompanyType, ContractStatusType, EntityTypeType } from '../../../../models/core/cache.type';
import { ContractSearchResponseType } from '../../../../models/core/contracts.type';
import contractsService from '../../../../services/core/contracts.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import DataView from './components/DataView';
import { Filter, FilterData } from './components/Filter';

function ContractSearch() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
    entityTypeData: EntityTypeType[];
    contractStatusData: ContractStatusType[];
  }>({ companyData: [], entityTypeData: [], contractStatusData: [] });

  const [data, setData] = useState<ContractSearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      setFilterData({
        companyData: await cacheUtils.listCompanies(),
        entityTypeData: await cacheUtils.listEntityTypes(),
        contractStatusData: await cacheUtils.listContractStatuses(),
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.ContractSearch) || '[]'
      ) as ContractSearchResponseType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        filter({
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
          entidadeData: null,
          idEntidade: null,
          entidade: '',
          listaStatus: [],
          dataInicial: null,
          dataFinal: null,
        });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.ContractSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      inputFilterData.entidadeData = null;

      const result = await contractsService.search({
        ...inputFilterData,
        idCliente: inputFilterData.idEntidade,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.ContractSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Contratos',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar contratos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (
    item: ContractSearchResponseType,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    try {
      let completedAction;
      setLoading(true);

      switch (action) {
        case 'excluir':
          await contractsService.delete({ idContrato: item.idContrato });
          setData(data.filter((x) => x.idContrato !== item.idContrato));
          completedAction = 'excluído';
          break;
        case 'cancelar':
          await contractsService.updateStatus(
            JSON.parse(
              JSON.stringify({
                idContrato: item.idContrato,
                codigoContratoStatus: ContractStatusEnum.Cancelado,
                codigoContratoStatusMotivo: null,
                observacao: null,
              })
            )
          );
          data[data.findIndex((x) => x.idContrato === item.idContrato)] = {
            ...item,
            codigoContratoStatus: ContractStatusEnum.Cancelado,
            contratoStatus: 'Cancelado',
          };
          setData(JSON.parse(JSON.stringify(data)));
          completedAction = 'cancelado';
          break;
        case 'visualizar':
          navigate(`/contracts/${item.idContrato}`);
          return;
        default:
          break;
      }

      showNotification({
        title: 'Contratos',
        message: `Contrato ${completedAction} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Contratos',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} o contrato.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Contract}
        title="Contratos"
        description="Gerencie contratos."
        buttons={[]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter
            companies={filterData.companyData}
            entityTypeData={filterData.entityTypeData}
            contractStatuses={filterData.contractStatusData}
            filter={filter}
            clear={clear}
            loading={loading}
          />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default ContractSearch;
