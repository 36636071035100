/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Space, Text, ThemeIcon } from '@mantine/core';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AlertCircle, Filter, List, Share } from 'tabler-icons-react';
import { Status, TipoCodigo } from '../../../../../business/events/general';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { EventSearchResponseType } from '../../../../../models/core/events.type';
import { UserType } from '../../../../../models/core/users.type';
import { Feature } from '../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../utils/formatter.utils';
import DataViewActions from './DataViewActions';
import DataViewBulkActions from './DataViewBulkActions';
import { newGuid } from '../../../../../utils/helper.utils';

type DataViewProps = {
  users: UserType[];
  data: EventSearchResponseType[] | undefined;
  showDocs: boolean;
  confirmActionResult(
    items: EventSearchResponseType[],
    action: string,
    value: string | null,
    confirmed: boolean
  ): void;
};

export default function DataView(props: DataViewProps) {
  const [currentUser] = useCurrentUser();
  const queryParams = new URLSearchParams(useLocation().search);
  const pageFilter = queryParams.get('filter') || '';

  // TODO: App: 118 - context api works better here
  const [tempSelectedData, setTempSelectedData] = useState<number[]>([]);
  const [selectedData, setSelectedData] = useState<
    {
      idEvento: number;
      idContrato: number | null;
      idEntidade: number | null;
      idProposta: number | null;
      idEventoVeiculoCompartilhado: number | null;
      codigoEventoStatus: Status;
      codigoEventoTipo: TipoCodigo;
      data: Date;
      observacao: string | null;
    }[]
  >([]);

  const columns: ColumnsType<any> = [
    {
      title: '#',
      key: 'idEvento',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
        const aValue = a.idEvento;
        const bValue = b.idEvento;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventSearchResponseType) => (
        <Link to={`?id=${row.idEvento}`} target="_blank">
          {row.idEvento}
        </Link>
      ),
    },
    currentUser.executivo
      ? {
          title: 'Empresa',
          key: 'empresa',
          dataIndex: 'empresa',
          sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
            a.empresa.localeCompare(b.empresa),
        }
      : {},
    !pageFilter
      ? {
          title: 'Audiência',
          key: 'eventoAudiencia',
          sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
            a.eventoAudiencia.localeCompare(b.eventoAudiencia),
          render: (row: EventSearchResponseType) => <Badge variant="outline">{row.eventoAudiencia}</Badge>,
        }
      : {},
    {
      title: 'Tipo',
      key: 'eventoTipo',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
        a.eventoTipo.localeCompare(b.eventoTipo),
      render: (row: EventSearchResponseType) => <Badge variant="outline">{row.eventoTipo}</Badge>,
    },
    {
      title: 'Fonte',
      key: 'fonte',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
        const aValue = a.idContrato
          ? `C${a.idContrato}`
          : a.idEntidade
          ? `E${a.idEntidade}`
          : a.idProposta
          ? `P${a.idProposta}`
          : '-';
        const bValue = b.idContrato
          ? `C${b.idContrato}`
          : b.idEntidade
          ? `E${b.idEntidade}`
          : b.idProposta
          ? `P${b.idProposta}`
          : '-';
        return aValue.localeCompare(bValue);
      },
      render: (row: EventSearchResponseType) => {
        if (row.idContrato) {
          return (
            <ProfileCardLink
              id={row.idContrato.toString()}
              name="Contrato"
              nameSize="sm"
              description={`# ${row.idContrato}`}
              descriptionSize="xs"
              linkPrefix="contracts"
              showLink
            />
          );
        }
        if (row.idEntidade) {
          return (
            <ProfileCardLink
              id={row.idEntidade.toString()}
              name="Entidade"
              nameSize="sm"
              description={`# ${row.idEntidade}`}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        if (row.idProposta) {
          return (
            <ProfileCardLink
              id={row.idProposta.toString()}
              name="Proposta"
              nameSize="sm"
              description={`# ${row.idProposta}`}
              descriptionSize="xs"
              linkPrefix="proposals"
              showLink
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Cliente/Entidade',
      key: 'clienteEntidade',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
        const clienteA = a.resumoParcialJSON?.cliente;
        const entidadeA = a.resumoParcialJSON?.entidade;
        const aValue =
          clienteA?.clienteNomeFantasia ||
          clienteA?.clienteRazaoSocial ||
          clienteA?.clienteNome ||
          entidadeA?.nomeFantasia ||
          entidadeA?.razaoSocial ||
          entidadeA?.nome ||
          '-';

        const clienteB = b.resumoParcialJSON?.cliente;
        const entidadeB = b.resumoParcialJSON?.entidade;
        const bValue =
          clienteB?.clienteNomeFantasia ||
          clienteB?.clienteRazaoSocial ||
          clienteB?.clienteNome ||
          entidadeB?.nomeFantasia ||
          entidadeB?.razaoSocial ||
          entidadeB?.nome ||
          '-';

        return aValue.localeCompare(bValue);
      },
      render: (row: EventSearchResponseType) => {
        const { cliente, entidade } = row.resumoParcialJSON || {};
        const entidadeRender = {
          id: cliente?.idCliente || entidade?.idEntidade,
          cnpj: cliente?.clienteCNPJ || entidade?.cnpj,
          nomePJ:
            cliente?.clienteNomeFantasia ||
            cliente?.clienteRazaoSocial ||
            entidade?.nomeFantasia ||
            entidade?.razaoSocial,
          descricaoPJ:
            cliente?.clienteNomeFantasia || entidade?.nomeFantasia
              ? cliente?.clienteRazaoSocial || entidade?.razaoSocial
              : cliente?.clienteCNPJ || entidade?.cnpj,
          nomePF: cliente?.clienteNome || entidade?.nome,
          descricaoPF: cliente?.clienteCPF || entidade?.cpf,
        };
        if (row.idContrato) {
          return (
            <ProfileCardLink
              id={row.idContrato.toString()}
              name={entidadeRender.nomePJ || entidadeRender.nomePF || '?'}
              nameSize="sm"
              description={entidadeRender.descricaoPJ || entidadeRender.descricaoPF}
              descriptionSize="xs"
              linkPrefix="contracts"
              showLink
            />
          );
        }
        if (row.idEntidade) {
          return (
            <ProfileCardLink
              id={row.idEntidade.toString()}
              name={entidadeRender.nomePJ || entidadeRender.nomePF || '?'}
              nameSize="sm"
              description={entidadeRender.descricaoPJ || entidadeRender.descricaoPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        if (row.idProposta) {
          return (
            <ProfileCardLink
              id={row.idProposta.toString()}
              name={entidadeRender.nomePJ || entidadeRender.nomePF || '?'}
              nameSize="sm"
              description={entidadeRender.descricaoPJ || entidadeRender.descricaoPF}
              descriptionSize="xs"
              linkPrefix="proposals"
              showLink
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Descrição',
      key: 'descricao',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
        const aValue = a.automatico
          ? a.descricao
          : a.resumoParcialJSON?.residuo?.residuoCliente ||
            a.resumoParcialJSON?.servico?.servico ||
            a.descricao;
        const bValue = b.automatico
          ? b.descricao
          : b.resumoParcialJSON?.residuo?.residuoCliente ||
            b.resumoParcialJSON?.servico?.servico ||
            b.descricao;
        return (aValue || '').localeCompare(bValue || '');
      },
      render: (row: EventSearchResponseType) => {
        let value = row.automatico
          ? row.descricao
          : `${
              row.resumoParcialJSON?.residuo?.residuoCliente || row.resumoParcialJSON?.servico?.servico || '-'
            } | ${row.descricao}`;
        if (value.startsWith('- |')) {
          value = value.replace('- |', '');
        }
        return <TableCellEllipsis label={value} numberOfChars={50} toolTipWidth={300} />;
      },
    },
    {
      title: 'Status',
      key: 'status',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
        a.eventoStatus.localeCompare(b.eventoStatus),
      render: (row: EventSearchResponseType) => <Badge variant="outline">{row.eventoStatus}</Badge>,
    },
    {
      title: 'Data',
      key: 'data',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
        new Date(a.data).valueOf() - new Date(b.data).valueOf(),
      render: (row: EventSearchResponseType) => formatDateToString(row.data),
    },
    {
      title: 'Responsável',
      key: 'responsavel',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
        (a.responsavel || '').localeCompare(b.responsavel || ''),
      render: (row: EventSearchResponseType) => {
        if (row.idResponsavel) {
          return <ProfileCardLink id={row.idResponsavel || ''} name={row.responsavel || ''} nameSize="sm" />;
        }
        return '-';
      },
    },
    {
      title: (
        <Text style={{ display: 'flex' }}>
          <Feature.Reference.Vehicle.icon size={16} />
        </Text>
      ),
      key: 'idEventoVeiculoCompartilhado',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
        const aValue = a.idEventoVeiculoCompartilhado ?? -1;
        const bValue = b.idEventoVeiculoCompartilhado ?? -1;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventSearchResponseType) => {
        if (!row.idEventoVeiculoCompartilhado) {
          return '-';
        }
        return (
          <Group spacing={2}>
            <div>F{row.idEventoVeiculoCompartilhado}</div>
            {!row.resumoParcialJSON?.veiculoCompartilhado ? <Share size={16} /> : <></>}
          </Group>
        );
      },
    },
    // render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    {
      title: 'Obsoleto',
      key: 'obsoleto',
      dataIndex: 'obsoleto',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) =>
        a.obsoleto === b.obsoleto ? 0 : a.obsoleto ? -1 : 1,
      render: (row: boolean) => {
        if (!row) {
          return '-';
        }
        return <AlertCircle size={16} />;
      },
    },
    {
      title: 'Ações',
      width: '170px',
      render: (row: EventSearchResponseType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ].filter((x) => x.title);

  if (props.showDocs) {
    columns.splice(columns.length - 1, 0, {
      title: 'Documentos',
      key: 'documentos',
      sorter: (a: EventSearchResponseType, b: EventSearchResponseType) => {
        const aValue = (a.resumoParcialJSON?.documentos || []).length;
        const bValue = (b.resumoParcialJSON?.documentos || []).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventSearchResponseType) => {
        const documentos = row.resumoParcialJSON?.documentos || [];
        if (documentos.length > 0) {
          return (
            <Group spacing="xs">
              {documentos.map((y) => {
                return (
                  <Badge
                    key={newGuid()}
                    variant="outline"
                  >{`${y.documentoTipo} - ${y.numeroDocumento}`}</Badge>
                );
              })}
            </Group>
          );
        }
        return '-';
      },
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EventSearchResponseType[]) => {
      setSelectedData(
        selectedRows.map((x) => {
          return {
            idEvento: x.idEvento,
            idContrato: x.idContrato,
            idEntidade: x.idEntidade,
            idProposta: x.idProposta,
            idEventoVeiculoCompartilhado: x.idEventoVeiculoCompartilhado,
            codigoEventoStatus: x.codigoEventoStatus,
            codigoEventoTipo: x.codigoEventoTipo,
            data: x.data,
            observacao: x.observacao,
            // resumoParcialJSON: x.resumoParcialJSON,
          };
        })
      );
    },
    getCheckboxProps: (record: EventSearchResponseType) => ({
      name: record.idEvento.toString(),
    }),
  };

  const buildResultsWithNoPageFilter = () => {
    return (
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        rowKey={(item: EventSearchResponseType) => item.idEvento}
        // expandable={{
        //   rowExpandable: (row) => !!row.resumoParcialJSON,
        //   expandedRowRender: (row) =>
        //     row.resumoParcialJSON ? (
        //       <Paper shadow="xs" p="md" withBorder>
        //         <Summary data={[{ ...buildFakeAuditObject(), ...row }]} />
        //       </Paper>
        //     ) : (
        //       <></>
        //     ),
        // }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100, 250, 500],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    );
  };

  const buildResultsWithPageFilter = useMemo(() => {
    const tabs = [
      {
        key: 'Todos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Event.color} variant="outline">
              <List size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Event.color} weight={500}>
              Todos ({props.data?.length})
            </Text>
          </Group>
        ),
        children: (
          <Table
            showSorterTooltip={false}
            dataSource={props.data}
            columns={columns.filter((x) => x.key !== 'eventoAudiencia')}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            rowKey={(item: EventSearchResponseType) => item.idEvento}
            // expandable={{
            //   rowExpandable: (row) => !!row.resumoParcialJSON,
            //   expandedRowRender: (row) =>
            //     row.resumoParcialJSON ? (
            //       <Paper shadow="xs" p="md" withBorder>
            //         <Summary data={[{ ...buildFakeAuditObject(), ...row }]} />
            //       </Paper>
            //     ) : (
            //       <></>
            //     ),
            // }}
            pagination={{
              pageSizeOptions: [10, 25, 50, 100, 250, 500],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        ),
        forceRender: true,
      },
    ];

    for (const tipo of Array.from(new Set((props?.data || []).map((x) => x.eventoTipo)))) {
      const tipoData = (props?.data || []).filter((x: any) => x?.eventoTipo === tipo);

      tabs.push({
        key: tipo,
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Event.color} variant="outline">
              <Filter size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Event.color} weight={500}>
              {tipo} ({tipoData.length})
            </Text>
          </Group>
        ),
        children: (
          <Table
            showSorterTooltip={false}
            dataSource={tipoData}
            columns={columns.filter((x) => x.key !== 'eventoAudiencia' && x.key !== 'eventoTipo')}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            rowKey={(item: EventSearchResponseType) => item.idEvento}
            // expandable={{
            //   rowExpandable: (row) => !!row.resumoParcialJSON,
            //   expandedRowRender: (row) =>
            //     row.resumoParcialJSON ? (
            //       <Paper shadow="xs" p="md" withBorder>
            //         <Summary data={[{ ...buildFakeAuditObject(), ...row }]} />
            //       </Paper>
            //     ) : (
            //       <></>
            //     ),
            // }}
            pagination={{
              pageSizeOptions: [10, 25, 50, 100, 250, 500],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        ),
        forceRender: true,
      });
    }

    return <Tabs items={tabs} defaultActiveKey="Todos" />;
  }, [columns, props.data, rowSelection]);

  const confirmActionResult = (
    items: EventSearchResponseType[],
    action: string,
    value: string | null,
    confirmed: boolean
  ) => {
    props.confirmActionResult(items, action, value, confirmed);
    setTempSelectedData(items.map((x) => x.idEvento));
  };

  useEffect(() => {
    if (props.data?.length === 0) {
      setSelectedData([]);
    }

    if (tempSelectedData.length > 0) {
      setSelectedData(props.data?.filter((x) => tempSelectedData.includes(x.idEvento)) || []);
    }
  }, [props.data]);

  return (
    <div>
      <DataViewBulkActions
        users={props.users}
        data={props.data || []}
        selectedData={selectedData}
        confirmActionResults={confirmActionResult}
      />
      <Space h="sm" />

      {pageFilter ? buildResultsWithPageFilter : buildResultsWithNoPageFilter()}
    </div>
  );
}
