import { ProposalStatusEnum, ProposalTypeEnum } from '../../business/proposals/status';
import { EntityItemEnum } from '../../utils/constants.utils';
import { Action, AuditType, ItemToleranceType } from './core.type';

export enum ProposalItemEnum {
  Residue = 'Resíduo',
  Service = 'Serviço',
}

// ### Begin - API CRUD ###
type ProposalSearchRequestType = {
  idPropostaPai?: string | null;
  idEmpresa?: string | null;
  listaStatus?: string[] | null;
  idCliente?: number | null;
  limit: number;
  offset: number;
};

type ProposalSearchResponseType = {
  idProposta: number;
  idPropostaPai: number | null;
  idEmpresa: number;
  empresa: string;
  codigoPropostaTipo: ProposalTypeEnum;
  propostaTipo: string;
  codigoPropostaStatus: ProposalStatusEnum;
  propostaStatus: string;
  codigoFechamento: string;
  fechamento: string;
  fechamentoDias: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;
  clienteAceitaEncontroContas: boolean;

  idEntidadeContato: number;
  nome: string;
  telefone: string | null;
  celular: string | null;
  email: string | null;
  setor: string | null;

  idGerenciador: number | null;
  gerenciadorCNPJ: string | null;
  gerenciadorRazaoSocial: string | null;
  gerenciadorNomeFantasia: string | null;
  gerenciadorCPF: string | null;
  gerenciadorNome: string | null;
  gerenciadorAceitaEncontroContas: boolean;

  dataInicial: Date;
  dataFinal: Date;
  validade: Date;
  observacao: string | null;
} & AuditType;

type ProposalSelectType = {
  idProposta: number;
  cache?: boolean;
};

type ProposalUpdateType = {
  action: Action;
  idProposta: number;
  idPropostaPai: number | null;
  idEmpresa: number;
  codigoPropostaTipo: ProposalTypeEnum;
  codigoFechamento: string;
  idCliente: number;
  idEntidadeContato: number;
  idGerenciador: number | null;
  dataInicial: Date;
  dataFinal: Date;
  documentacaoCliente: string | null;
  documentacaoClienteRecorrenciaDias: number | null;
  observacao: string | null;
  residuos: ProposalResidueType[];
  servicos: ProposalServiceType[];
};

type ProposalDeleteType = {
  idProposta: number;
};

type ProposalInsertType = {
  action: Action;
  idPropostaPai: number | null;
  idEmpresa: number;
  codigoPropostaTipo: ProposalTypeEnum;
  codigoFechamento: string;
  idCliente: number;
  idEntidadeContato: number;
  idGerenciador: number | null;
  dataInicial: Date;
  dataFinal: Date;
  documentacaoCliente: string | null;
  documentacaoClienteRecorrenciaDias: number | null;
  observacao: string | null;
  residuos: ProposalResidueType[];
  servicos: ProposalServiceType[];
};

type ProposalStatusUpdateType = {
  idProposta: number;
  idContrato: number | null;
  codigoPropostaStatus: ProposalStatusEnum;
  dataInicial: Date | null;
  dataFinal: Date | null;
  observacao: string | null;
};

type ProposalFinancialPatchType = {
  idProposta: number;
  acondicionamentos: ({
    idPropostaResiduoPlanoCotacaoAcondicionamento: number;
  } & ProposalFinancialType)[];
  equipamentos: ({
    idPropostaResiduoPlanoCotacaoEquipamento: number;
  } & ProposalFinancialType)[];
  veiculos: ({
    idPropostaResiduoPlanoCotacaoVeiculo: number;
  } & ProposalFinancialType)[];
  tratamentos: ({
    idPropostaResiduoPlanoCotacaoTratamento: number;
  } & ProposalFinancialType)[];
  destinosFinais: ({
    idPropostaResiduoPlanoCotacaoDestinoFinal: number;
  } & ProposalFinancialType)[];
  servicos: ({
    idPropostaServicoCotacao: number;
  } & ProposalFinancialType)[];
};

type ProposalFinancialType = {
  margem: number | null;
  precoFinal: number | null;
  imposto: number;
};
// ### End - API CRUD ###

type ProposalType = {
  action?: Action;
  idProposta: number;
  idPropostaPai: number | null;
  idEmpresa: number;
  empresa: string;
  codigoPropostaTipo: ProposalTypeEnum;
  propostaTipo: string;
  codigoPropostaStatus: ProposalStatusEnum;
  propostaStatus: string;
  codigoFechamento: string;
  fechamento: string;
  fechamentoDias: number;

  idEntidadeContato: number;
  nome: string;
  telefone: string | null;
  celular: string | null;
  email: string | null;
  setor: string | null;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;
  clienteAceitaEncontroContas: boolean;

  idGerenciador: number | null;
  gerenciadorCNPJ: string | null;
  gerenciadorRazaoSocial: string | null;
  gerenciadorNomeFantasia: string | null;
  gerenciadorCPF: string | null;
  gerenciadorNome: string | null;
  gerenciadorEncontroContas: boolean;

  dataInicial: Date;
  dataFinal: Date;
  validadeLembreteDias: number | null;
  validade: Date;
  documentacaoCliente: string | null;
  documentacaoClienteRecorrenciaDias: number | null;
  observacao: string | null;
  status: ProposalStatusType[];
  residuos: ProposalResidueType[];
  servicos: ProposalServiceType[];
  comissoes: ProposalCommissionType[];
} & AuditType;

type ProposalStatusType = {
  idPropostaStatus: number;
  idProposta: number;
  codigoPropostaStatus: string;
  propostaStatus: string;
  codigoPropostaStatusMotivo: string | null;
  propostaStatusMotivo: string | null;
  observacao: string | null;
} & AuditType;

// ### Begin - Proposal Residue ###
// TODO: App: 044 - create other types like cliente, endereco, unidadeMedida, precos, etc.
type ProposalResidueType = {
  id?: string;
  action?: Action;
  idPropostaResiduo?: number | null;
  idPropostaResiduoPai?: number | null;
  idProposta: number;
  idResiduo: number;
  residuo: string;
  codigoIBAMA: string;
  perigoso: boolean;
  codigoResiduoEstadoFisico: string;
  residuoEstadoFisico: string;
  residuoCliente: string;
  residuoClientePerigoso: boolean;
  idEntidadeEndereco: number;
  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;
  codigoEstado: string;
  cidade: string;
  cidadeCodigoIBGE: number | null;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string | null;
  referencia: string | null;
  cep: string;
  latitude: number | null;
  longitude: number | null;

  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida1: number | null;
  minimoAceitavelUnidadeMedida1: string | null;
  minimoAceitavelUnidadeMedidaSigla1: string | null;
  minimoAceitavelIdUnidadeMedida2: number | null;
  minimoAceitavelUnidadeMedida2: string | null;
  minimoAceitavelUnidadeMedidaSigla2: string | null;

  estimativa: number;
  estimativaIdUnidadeMedida1: number;
  estimativaUnidadeMedida1: string;
  estimativaUnidadeMedidaSigla1: string | null;
  estimativaIdUnidadeMedida2: number;
  estimativaUnidadeMedida2: string;
  estimativaUnidadeMedidaSigla2: string | null;

  dataInicial: Date;
  dataFinal: Date;
  faturamentoDireto: boolean;
  compra: boolean;
  cobrarTolerancia: boolean;

  tecnologiaAtual: string | null;
  observacao: string | null;

  documento: ProposalResidueDocumentType;
  recorrencia: ProposalRecurrenceType;
  planos: ProposalResiduePlanType[];
} & AuditType;

type ProposalResidueDocumentType = {
  id?: string;
  action?: Action;
  idPropostaResiduoDocumento?: number | null;
  idPropostaResiduo: number;
  notaFiscal: boolean;
  xml: boolean;
  mtr: boolean;
  sga: boolean;
  romaneioColeta: boolean;
  pesoOrigem: boolean;
  pesoDestino: boolean;
} & AuditType;

// ### Begin - Proposal Residue Plan ###
type ProposalResiduePlanType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlano: number;
  idPropostaResiduo: number;
  primario: boolean;
  observacao: string | null;
  acondicionamentos: ProposalResiduePlanPackagingType[];
  equipamentos: ProposalResiduePlanEquipmentType[];
  veiculo: ProposalResiduePlanVehicleType | null;
  tratamento: ProposalResiduePlanTreatmentType | null;
  destinoFinal: ProposalResiduePlanDestinationType | null;
  cotacoes: ProposalResiduePlanQuotationType[];
} & AuditType;

type ProposalResiduePlanPackagingType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoAcondicionamento?: number;
  idPropostaResiduoPlanoAcondicionamentoPai?: number;
  idPropostaResiduoPlano: number;

  idResiduoAcondicionamento: number;
  residuoAcondicionamento: string;
  residuoAcondicionamentoDescricao: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;

  idPropostaServico: number | null;
  idPropostaServicoTemp: string | null;
  compartilhado: boolean;
  gerenciar: boolean;

  observacao: string | null;
} & AuditType;

type ProposalResiduePlanEquipmentType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoEquipamento?: number;
  idPropostaResiduoPlanoEquipamentoPai?: number;
  idPropostaResiduoPlano: number;

  idResiduoEquipamento: number;
  residuoEquipamento: string;
  residuoEquipamentoDescricao: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;

  observacao: string | null;
} & AuditType;

type ProposalResiduePlanVehicleType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoVeiculo?: number;
  idPropostaResiduoPlano: number;

  idResiduoVeiculo: number;
  residuoVeiculo: string;
  residuoVeiculoDescricao: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;

  cargaMedia: number;
  cargaMediaIdUnidadeMedida: number;
  cargaMediaUnidadeMedida: string;
  cargaMediaUnidadeMedidaSigla: string | null;

  observacao: string | null;
} & AuditType;

type ProposalResiduePlanTreatmentType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoTratamento?: number;
  idPropostaResiduoPlano: number;

  idResiduoTratamento: number;
  residuoTratamento: string;
  residuoTratamentoDescricao: string | null;

  autorizacaoAmbiental: boolean;
  observacao: string | null;
} & AuditType;

type ProposalResiduePlanDestinationType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoDestinoFinal?: number;
  idPropostaResiduoPlano: number;

  idResiduoDestinoFinal: number;
  residuoDestinoFinal: string;
  residuoDestinoFinalDescricao: string | null;

  autorizacaoAmbiental: boolean;
  observacao: string | null;
} & AuditType;
// ### End - Proposal Residue Plan ###

// ### Being - Proposal Residue Plan Quotation ###
type ProposalResiduePlanQuotationType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoCotacao: number;
  idPropostaResiduoPlano: number;

  primario: boolean;
  observacao: string | null;

  acondicionamentos: ProposalResiduePlanQuotationPackagingType[];
  equipamentos: ProposalResiduePlanQuotationEquipmentType[];
  veiculo: ProposalResiduePlanQuotationVehicleType;
  tratamento: ProposalResiduePlanQuotationTreatmentType;
  destinoFinal: ProposalResiduePlanQuotationDestinationType;

  completo: boolean;
} & AuditType;

type ProposalResiduePlanQuotationPackagingType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoCotacaoAcondicionamento?: number;
  idPropostaResiduoPlanoCotacao?: number;
  idPropostaResiduoPlanoAcondicionamento?: number;

  idEntidadeResiduoAcondicionamento: number;
  idResiduoAcondicionamento: number;
  residuoAcondicionamento: string;
  residuoAcondicionamentoDescricao: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number | null;
  quantidadeUnidadeMedida?: string;
  quantidadeUnidadeMedidaSigla?: string | null;
  frequenciaIdUnidadeMedida: number | null;
  frequenciaUnidadeMedida?: string;
  frequenciaUnidadeMedidaSigla?: string | null;
  preco: number;

  cobrarSemUso: boolean;
  condicaoLocacao: string | null;

  margem?: number | null;
  precoFinal?: number | null;
  imposto?: number | null;
  observacao: string | null;

  tolerancias: (ItemToleranceType & { idPropostaResiduoAcondicionamento?: number })[];
} & AuditType;

type ProposalResiduePlanQuotationEquipmentType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoCotacaoEquipamento?: number;
  idPropostaResiduoPlanoCotacao?: number;
  idPropostaResiduoPlanoEquipamento?: number;

  idEntidadeResiduoEquipamento: number;
  idResiduoEquipamento: number;
  residuoEquipamento: string;
  residuoEquipamentoDescricao: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number | null;
  quantidadeUnidadeMedida?: string;
  quantidadeUnidadeMedidaSigla?: string | null;
  frequenciaIdUnidadeMedida: number | null;
  frequenciaUnidadeMedida?: string;
  frequenciaUnidadeMedidaSigla?: string | null;

  preco: number;

  cobrarSemUso: boolean;
  condicaoLocacao: string | null;

  margem?: number | null;
  precoFinal?: number | null;
  imposto?: number | null;
  observacao: string | null;

  tolerancias: (ItemToleranceType & { idPropostaResiduoEquipamento?: number })[];
} & AuditType;

type ProposalResiduePlanQuotationVehicleType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoCotacaoVeiculo?: number;
  idPropostaResiduoPlanoCotacao?: number;
  idPropostaResiduoPlanoVeiculo?: number;

  idEntidadeResiduoVeiculo: number;
  idResiduoVeiculo: number;
  residuoVeiculo: string;
  residuoVeiculoDescricao: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number | null;
  quantidadeUnidadeMedida?: string;
  quantidadeUnidadeMedidaSigla?: string | null;
  frequenciaIdUnidadeMedida: number | null;
  frequenciaUnidadeMedida?: string;
  frequenciaUnidadeMedidaSigla?: string | null;
  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida: number | null;
  minimoAceitavelUnidadeMedida: string | null;
  minimoAceitavelUnidadeMedidaSigla: string | null;

  preco: number;

  margem?: number | null;
  precoFinal?: number | null;
  imposto?: number | null;
  observacao: string | null;

  tolerancias: (ItemToleranceType & { idPropostaResiduoVeiculo?: number })[];
} & AuditType;

type ProposalResiduePlanQuotationTreatmentType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoCotacaoTratamento?: number;
  idPropostaResiduoPlanoCotacao?: number;
  idPropostaResiduoPlanoTratamento?: number;

  idEntidadeResiduoTratamento: number;
  idResiduoTratamento: number;
  residuoTratamento: string;
  residuoTratamentoDescricao: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idEntidadeEndereco: number;
  idEntidade: number;
  codigoEstado: string;
  cidade: string;
  cidadeCodigoIBGE: number | null;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string | null;
  referencia: string | null;
  cep: string;
  latitude: number | null;
  longitude: number | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida: number | null;
  minimoAceitavelUnidadeMedida: string | null;
  minimoAceitavelUnidadeMedidaSigla: string | null;

  preco: number;

  margem?: number | null;
  precoFinal?: number | null;
  imposto?: number | null;
  observacao: string | null;

  tolerancias: (ItemToleranceType & { idPropostaResiduoTratamento?: number })[];
} & AuditType;

type ProposalResiduePlanQuotationDestinationType = {
  id?: string;
  action?: Action;
  idTemporaria?: string;
  idPropostaResiduoPlanoCotacaoDestinoFinal?: number;
  idPropostaResiduoPlanoCotacao?: number;
  idPropostaResiduoPlanoDestinoFinal?: number;

  idEntidadeResiduoDestinoFinal: number;
  idResiduoDestinoFinal: number;
  residuoDestinoFinal: string;
  residuoDestinoFinalDescricao: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idEntidadeEndereco: number;
  idEntidade: number;
  codigoEstado: string;
  cidade: string;
  cidadeCodigoIBGE: number | null;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string | null;
  referencia: string | null;
  cep: string;
  latitude: number | null;
  longitude: number | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida: number | null;
  minimoAceitavelUnidadeMedida: string | null;
  minimoAceitavelUnidadeMedidaSigla: string | null;

  preco: number;
  receita: boolean;

  margem?: number | null;
  precoFinal?: number | null;
  imposto?: number | null;
  observacao: string | null;

  tolerancias: (ItemToleranceType & { idPropostaResiduoDestinoFinal?: number })[];
} & AuditType;
// ### End - Proposal Residue Plan Quotation ###

// ### End - Proposal Residue ###

// ### Begin - Proposal Service ###
type ProposalServiceType = {
  id?: string;
  action?: Action;
  idPropostaServico?: number;
  idPropostaServicoPai?: number | null;
  idProposta: number;
  idServico: number;
  servico: string;
  servicoDescricao: string | null;

  quantidade: number;
  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento?: string | null;
  residuoAcondicionamentoDescricao?: string | null;
  idResiduoEquipamento: number | null;
  residuoEquipamento?: string | null;
  residuoEquipamentoDescricao?: string | null;

  idEntidadeEndereco: number;
  idCliente?: number;
  clienteCNPJ?: string | null;
  clienteRazaoSocial?: string | null;
  clienteNomeFantasia?: string | null;
  clienteCPF?: string | null;
  clienteNome?: string | null;
  codigoEstado?: string;
  cidade?: string;
  cidadeCodigoIBGE?: number | null;
  bairro?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string | null;
  referencia?: string | null;
  cep?: string;
  latitude?: number | null;
  longitude?: number | null;

  dataInicial: Date;
  dataFinal: Date;
  frequencia: number | null;
  frequenciaIdUnidadeMedida1: number | null;
  frequenciaUnidadeMedida1?: string;
  frequenciaUnidadeMedidaSigla1?: string | null;
  frequenciaIdUnidadeMedida2: number | null;
  frequenciaUnidadeMedida2?: string;
  frequenciaUnidadeMedidaSigla2?: string | null;
  cobrarTolerancia: boolean;
  compartilhado: boolean;
  gerenciar: boolean;

  turno: string | null;
  jornada: string | null;

  observacao: string | null;
  recorrencia: ProposalRecurrenceType;
  cotacoes: ProposalServiceQuotationType[];
} & AuditType;

type ProposalRecurrenceType = {
  id?: string;
  action?: Action;
  idPropostaRecorrencia?: number;
  idProposta?: number;
  idPropostaResiduo?: number;
  idPropostaServico?: number;
  idTemporaria?: string;

  dataInicial: Date;
  dataFinal: Date;
  quantidade: number | null;
  cada: number | null;
  frequencia: string | null;
  ocorrencia: number | null;
  diasSemana: number[] | null;
  diasMes: number[] | null;
  mesAno: number | null;
} & AuditType;

type ProposalServiceQuotationType = {
  id?: string;
  action?: Action;
  idPropostaServicoCotacao: number;
  idTemporaria?: string;
  idPropostaServico: number;

  idServico: number;
  servico: string;
  servicoDescricao: string | null;
  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento?: string | null;
  residuoAcondicionamentoDescricao?: string | null;
  idResiduoEquipamento: number | null;
  residuoEquipamento?: string | null;
  residuoEquipamentoDescricao?: string | null;

  idEntidadeServico: number | null; // populated by the response
  idEntidadeAcondicionamento: number | null;
  idEntidadeResiduoAcondicionamento?: number | null; // populated by the response
  idEntidadeEquipamento: number | null;
  idEntidadeResiduoEquipamento?: number | null; // populated by the response

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  quantidade: number;

  frequencia: number | null;
  frequenciaIdUnidadeMedida1: number | null;
  frequenciaUnidadeMedida1?: string;
  frequenciaUnidadeMedidaSigla1?: string | null;
  frequenciaIdUnidadeMedida2: number | null;
  frequenciaUnidadeMedida2?: string;
  frequenciaUnidadeMedidaSigla2?: string | null;
  preco: number;

  primario: boolean;

  margem?: number | null;
  precoFinal?: number | null;
  imposto?: number | null;
  observacao: string | null;

  tolerancias: (ItemToleranceType & { idPropostaServicoCotacao?: number })[];
} & AuditType;
// ### End - Proposal Service ###

// ### Begin - Proposal Financial ###
type ProposalCommissionType = {
  id?: string;
  action?: Action;
  idPropostaComissao?: number;
  idPropostaComissaoPai?: number | null;
  idProposta: number;

  idEntidade: number;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  cpf: string | null;
  nome: string | null;

  porcentagem: number | null;
  preco: number | null;
  ocorrencia: number | null;

  observacao: string | null;
} & AuditType;

type ProposalEstimateType = {
  id: number;
  grupoId: number;
  grupo: ProposalItemEnum;
  tipo: EntityItemEnum;
  item: string;
  subItem: string;
  compra: boolean;
  dataInicial: Date;
  dataFinal: Date;
  estimativa: number | null;
  estimativaIdUnidadeMedida1: number | null;
  estimativaIdUnidadeMedida2: number | null;
  recorrencia: ProposalRecurrenceType;
  plano: {
    quantidade: number | null;
    quantidadeIdUnidadeMedida: number | null;
    frequencia: number | null;
    frequenciaIdUnidadeMedida: number | null;
    cargaMedia: number | null;
    cargaMediaIdUnidadeMedida: number | null;
  };
  cotacao: {
    preco: number;
    receita: boolean;
    margem: number | null | undefined;
    precoFinal: number | null | undefined;
    imposto: number;
    quantidade: number;
    quantidadeIdUnidadeMedida: number;
    frequenciaIdUnidadeMedida: number | null;
  };
};
// ### End - Proposal Financial ###

export type {
  ItemToleranceType,
  ProposalCommissionType,
  ProposalDeleteType,
  ProposalEstimateType,
  ProposalFinancialPatchType,
  ProposalInsertType,
  // sub-types
  ProposalRecurrenceType,
  ProposalResiduePlanDestinationType,
  ProposalResiduePlanEquipmentType,
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanQuotationDestinationType,
  ProposalResiduePlanQuotationEquipmentType,
  ProposalResiduePlanQuotationPackagingType,
  ProposalResiduePlanQuotationTreatmentType,
  ProposalResiduePlanQuotationType,
  ProposalResiduePlanQuotationVehicleType,
  ProposalResiduePlanTreatmentType,
  ProposalResiduePlanType,
  ProposalResiduePlanVehicleType,
  ProposalResidueType,
  ProposalSearchRequestType,
  ProposalSearchResponseType,
  ProposalSelectType,
  ProposalServiceQuotationType,
  ProposalServiceType,
  ProposalStatusUpdateType,
  ProposalType,
  ProposalUpdateType,
};
