/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Center, Group, Loader, Paper, Space } from '@mantine/core';
// import { showNotification } from '@mantine/notifications';
import { showNotification } from '@mantine/notifications';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import lodash from 'lodash';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import { Tipo, TipoCodigo } from '../../../../../business/events/general';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import { EventBasicSearchResponseType, EventType } from '../../../../../models/core/events.type';
import eventsService from '../../../../../services/core/events.service';
import { Feature } from '../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../utils/formatter.utils';
import Summary from '../../components/Summary';

type DataResult = {
  loading: boolean;
  eventData: EventBasicSearchResponseType[];
};

type DataViewProps = {
  data: number[];
  event: EventType | null;
};

export default function SummaryView(props: DataViewProps) {
  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    eventData: [],
  });

  const getTipo = (codigoEventoTipo: TipoCodigo) => {
    switch (codigoEventoTipo) {
      case TipoCodigo.Cobranca:
        return Tipo.Cobranca;
      case TipoCodigo.Coleta:
        return Tipo.Coleta;
      case TipoCodigo.Comissao:
        return Tipo.Comissao;
      case TipoCodigo.Documetacao:
        return Tipo.Documetacao;
      case TipoCodigo.Expiracao:
        return Tipo.Expiracao;
      case TipoCodigo.Fechamento:
        return Tipo.Fechamento;
      case TipoCodigo.Lembrete:
        return Tipo.Lembrete;
      case TipoCodigo.MobilizacaoEnviar:
        return Tipo.MobilizacaoEnviar;
      case TipoCodigo.MobilizacaoRetirar:
        return Tipo.MobilizacaoRetirar;
      default:
        return '?';
    }
  };

  const populateAndDownload = async () => {
    const templatePath = `${process.env.PUBLIC_URL}/templates/coleta.xlsx?v=${Date.now()}`;

    const response = await fetch(templatePath);
    const buffer = await response.arrayBuffer();

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);
    workbook.creator = 'Performa Manager';
    workbook.lastModifiedBy = 'Performa Manager';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.worksheets[0];

    const populate = (initRowIndex: number) => {
      let rowIndex = initRowIndex;
      const styledRow = worksheet.getRow(initRowIndex);

      dataResult.eventData
        .filter((x) => x.codigoEventoTipo === TipoCodigo.Coleta)
        .forEach((item) => {
          let { residuo, veiculo, veiculoCompartilhado, tratamento, destinoFinal } = item.resumoJSON || {};

          veiculo = veiculoCompartilhado ?? veiculo;
          residuo = residuo!;

          const newRow = worksheet.insertRow(rowIndex + 1, [
            null,
            // eslint-disable-next-line prettier/prettier
            residuo.cliente.clienteNomeFantasia ||
              residuo.cliente.clienteRazaoSocial ||
              residuo.cliente.clienteNome, // CLIENTE
            `${residuo.local.logradouro}, ${residuo.local.numero}${
              residuo.local.complemento ? ` - ${residuo.local.complemento}` : ''
            } | ${residuo.local.bairro} - ${residuo.local.cidade} / ${residuo.local.codigoEstado} (${
              residuo.local.cep
            })`, // LOCAL
            residuo.residuoCliente, // RESÍDUO
            formatDateToString(item.data), // DATA
            item.observacao ?? '-', // OBSERVACAO
            veiculo?.residuoVeiculo
              ? `${veiculo?.residuoVeiculo}${
                  item.idEventoVeiculoCompartilhado ? ` (F${item.idEventoVeiculoCompartilhado})` : ''
                }`
              : '-', // TRANSP. TIPO
            // eslint-disable-next-line prettier/prettier
            veiculo?.cotacao.fornecedorNomeFantasia ||
              veiculo?.cotacao.fornecedorRazaoSocial ||
              veiculo?.cotacao.fornecedorNome ||
              '-', // TRANSP. FORNECEDOR
            // veiculo?.acondicionamentos && veiculo?.acondicionamentos?.length > 0
            //   ? veiculo?.acondicionamentos
            //       .map((y) => {
            //         return `${y.enviar ? 'Enviar' : 'Retirar'} ${y.quantidade} ${y.residuoAcondicionamento}`;
            //       })
            //       .join(' | ')
            //   : '-', // TRANSP. ACOND
            veiculo?.acondicionamentos?.[0]?.residuoAcondicionamento || '-', // TRANSP. ACOND
            veiculo?.acondicionamentos?.[0]?.quantidade || '-', // QUANTIDADE

            tratamento?.residuoTratamento || '-', // TRAT. TIPO
            // eslint-disable-next-line prettier/prettier
            tratamento?.cotacao.fornecedorNomeFantasia ||
              tratamento?.cotacao.fornecedorRazaoSocial ||
              tratamento?.cotacao.fornecedorNome ||
              '-', // TRAT. FORNECEDOR
            destinoFinal?.residuoDestinoFinal || '-', // DEST TIPO
            // eslint-disable-next-line prettier/prettier
            destinoFinal?.cotacao.fornecedorNomeFantasia ||
              destinoFinal?.cotacao.fornecedorRazaoSocial ||
              destinoFinal?.cotacao.fornecedorNome ||
              '-', // DEST FORNECEDOR
          ]);

          newRow.eachCell((cell, colNumber) => {
            cell.style = { ...styledRow.getCell(colNumber).style };
          });
          rowIndex++;
        });

      worksheet.spliceRows(initRowIndex, 1);
      return rowIndex;
    };

    const autoFitColumns = (finalRowIndex: number) => {
      for (let col = 2; col <= 13; col++) {
        // Columns B (2) to M (13)
        let maxLength = 0;

        for (let row = 1; row <= finalRowIndex; row++) {
          const cell = worksheet.getRow(row).getCell(col);
          const cellLength = cell.value ? cell.toString().length : 0;
          maxLength = Math.max(maxLength, cellLength);
        }

        worksheet.getColumn(col).width = maxLength + 5;
      }
    };

    const lastRowIndex = populate(10);
    autoFitColumns(lastRowIndex);

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `Coleta_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
  };

  const buildGroupedData = () => {
    const nodes: ReactNode[] = [];
    const groupedData = lodash.groupBy(dataResult.eventData, 'codigoEventoTipo');

    for (const key of Object.keys(groupedData)) {
      const node = (
        <>
          <Group position="apart">
            <PageSection
              size="lg"
              color={Feature.Home.Event.color}
              label={getTipo(key as TipoCodigo)}
              text=""
            />
            {key === TipoCodigo.Coleta && (
              <Button
                color="accent"
                leftIcon={<FileExcelOutlined size={18} />}
                onClick={() => {
                  populateAndDownload();
                }}
              >
                Exportar
              </Button>
            )}
          </Group>
          <Space h="xs" />
          <Summary data={groupedData[key]} />
        </>
      );
      nodes.push(node);
      nodes.push(<Space h="lg" />);
    }
    nodes.pop();

    return nodes;
  };

  useEffect(() => {
    const fetchData = async () => {
      let eventData: any = null;
      try {
        eventData = await eventsService.searchBasic({ listaIds: props.data, limit: 1000, offset: 0 });
      } catch (err: any) {
        showNotification({
          title: `Evento - Resumos`,
          message: err?.isBusinessException ? err.description : 'Não foi possível carregar o(s) evento(s).',
          color: 'red',
        });
      } finally {
        setDataResult({
          loading: false,
          eventData,
        });
      }
    };
    if (props.event) {
      setDataResult({
        loading: false,
        eventData: [props.event],
      });
    } else {
      fetchData();
    }
  }, []);

  return (
    <div>
      {dataResult.loading ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <Paper shadow="xs" p="md" withBorder>
          {buildGroupedData()}
        </Paper>
      )}
    </div>
  );
}
