/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import {
  Alert,
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Menu,
  Modal,
  Paper,
  SimpleGrid,
  Space,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Tabs } from 'antd';
import moment from 'moment';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Activity,
  AlertTriangle,
  Certificate,
  CirclesRelation,
  Database,
  Edit,
  FileImport,
  Id,
  InfoCircle,
  Receipt2,
  Scale,
  Switch2,
  Trash,
} from 'tabler-icons-react';
import {
  Audiencia,
  AudienciaCodigo,
  ReferenciaCodigo,
  Relacao,
  Status,
  TipoCodigo,
  getStatusAction,
  getValidStatuses,
} from '../../../../business/events/general';
import PageViewProperty from '../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import {
  CompanyType,
  DocumentType,
  EventRelationTypeData,
  EventStatusReasonType,
  EventStatusType,
  MovementType,
  UnitOfMeasureType,
} from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';
import {
  EventBillingType,
  EventMeasurementType,
  EventSearchResponseType,
  EventType,
} from '../../../../models/core/events.type';
import { UserType } from '../../../../models/core/users.type';
import eventsService from '../../../../services/core/events.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { buildFakeAuditObject } from '../../../../utils/helper.utils';
import { validate } from '../../../../utils/permission.utils';
import BillingFormView from './components/BillingFormView';
import DocumentFormView from './components/DocumentFormView/DocumentFormView';
import GeneralView from './components/GeneralView';
import MeasurementFormView from './components/MeasurementFormView';
import ReferenceView from './components/ReferenceView';
import RelationFormView from './components/RelationFormView/RelationFormView';
import StatusChangeForm from './components/StatusChangeForm';
import StatusView from './components/StatusView';
import SummaryView from './components/SummaryView';

type DataResult = {
  loading: boolean;
  eventData: EventType | null;
};

type FormViewProps = {
  referenceData: {
    eventStatusData: EventStatusType[];
    documentTypeData: DocumentType[];
    companyData: CompanyType[];
    unitOfMeasures: UnitOfMeasureType[];
    movementTypeData: MovementType[];
    eventRelationTypeData: EventRelationTypeData[];
    eventStatusReasonData: EventStatusReasonType[];
    userData: UserType[];
  };
  idEvento: number;
  callback(
    items: EventSearchResponseType[] | null,
    action: string,
    value: string | null,
    confirmed: boolean
  ): void;
};

export default function EventView(props: FormViewProps) {
  const [currentUser] = useCurrentUser();
  const refStatusForm = useRef<any>(null);

  const [modalImportOpened, setModalImportOpened] = useState<boolean>(false);
  const [modalDeleteOpened, setModalDeleteOpened] = useState<boolean>(false);
  const [modalPrintOpened, setModalPrintOpened] = useState<boolean>(false);
  const [modalStatusData, setModalStatusData] = useState<{ opened: boolean; status: Status | null }>({
    opened: false,
    status: null,
  });

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    eventData: null,
  });
  const [importing, setImporting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const hasSummary = dataResult.eventData?.resumoJSON;

  const callbackMeasurement = (item: EventMeasurementType) => {
    setDataResult({ ...dataResult, eventData: { ...dataResult.eventData!, medicao: item } });
  };

  const callbackBilling = (item: EventBillingType) => {
    setDataResult({
      ...dataResult,
      eventData: {
        ...dataResult.eventData!,
        faturamento: item,
        relacoes: (item as any).relacoes,
        resumoJSON: item.evento.resumoJSON,
      },
    });
  };

  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: <GeneralView data={dataResult.eventData!} />,
      forceRender: true,
    },
    {
      key: 'Referencia',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Database size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Referência
          </Text>
        </Group>
      ),
      children: <ReferenceView data={dataResult.eventData!} />,
      disabled:
        dataResult.eventData?.codigoEventoReferencia !== ReferenciaCodigo.ContratoComissao &&
        dataResult.eventData?.codigoEventoReferencia !== ReferenciaCodigo.ContratoServico &&
        dataResult.eventData?.codigoEventoReferencia !== ReferenciaCodigo.ContratoResiduoAcondicionamento &&
        dataResult.eventData?.codigoEventoReferencia !== ReferenciaCodigo.ContratoResiduoEquipamento &&
        dataResult.eventData?.codigoEventoReferencia !== ReferenciaCodigo.ContratoResiduoPlano &&
        dataResult.eventData?.codigoEventoReferencia !== ReferenciaCodigo.EntidadeDocumento,

      forceRender: true,
    },
    {
      key: 'Documentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Certificate size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Documentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <DocumentFormView data={dataResult.eventData!} referenceData={props.referenceData} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Medição',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Medição
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <MeasurementFormView
            data={dataResult.eventData!}
            referenceData={props.referenceData}
            callbackMeasurement={callbackMeasurement}
          />
        </Paper>
      ),
      disabled:
        (dataResult.eventData?.codigoEventoStatus !== Status.PendenteMedicao &&
          dataResult.eventData?.codigoEventoStatus !== Status.Cancelado &&
          dataResult.eventData?.codigoEventoStatus !== Status.Finalizado) ||
        (dataResult.eventData.codigoEventoTipo !== TipoCodigo.Coleta &&
          dataResult.eventData.codigoEventoTipo !== TipoCodigo.MobilizacaoEnviar &&
          dataResult.eventData.codigoEventoTipo !== TipoCodigo.MobilizacaoRetirar &&
          dataResult.eventData.codigoEventoTipo !== TipoCodigo.Cobranca &&
          dataResult.eventData.codigoEventoTipo !== TipoCodigo.Comissao),
      forceRender: true,
    },
    {
      key: 'Faturamento',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Receipt2 size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Faturamento
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <BillingFormView
            data={dataResult.eventData!}
            referenceData={props.referenceData}
            callbackBilling={callbackBilling}
          />
        </Paper>
      ),
      disabled: dataResult.eventData?.codigoEventoTipo !== TipoCodigo.Fechamento,
      forceRender: true,
    },
    {
      key: 'Relacoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <CirclesRelation size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Relações
          </Text>
        </Group>
      ),
      children: (
        <RelationFormView
          key={(dataResult.eventData?.relacoes || []).length}
          event={dataResult.eventData!}
          referenceData={props.referenceData}
        />
      ),
      forceRender: true,
    },
    {
      key: 'Status',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Activity size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Status
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <StatusView data={dataResult.eventData!} />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  if (hasSummary) {
    tabs.splice(0, 0, {
      key: 'Resumo',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <InfoCircle size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Resumo
          </Text>
        </Group>
      ),
      children: (
        <SummaryView
          key={(dataResult.eventData?.resumoJSON?.toString() || '').length}
          data={[]}
          event={dataResult.eventData}
        />
      ),
      forceRender: true,
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      let eventData: any = null;
      try {
        eventData = await eventsService.select({ idEvento: Number(props.idEvento) });
        setDataResult({
          loading: false,
          eventData,
        });
      } catch (err: any) {
        showNotification({
          title: `Evento - Visualizar`,
          message: err?.isBusinessException ? err.description : 'Não foi possível carregar o evento.',
          color: 'red',
        });
        props.callback(
          [{ ...buildFakeAuditObject(), idEvento: props.idEvento }],
          'callback-remover',
          null,
          true
        );
      }
    };

    if (!validate(Permission.EventView, currentUser.permissoes)) {
      window.location.href = '/403';
      return;
    }
    fetchData();
  }, []);

  const confirmImport = () => {
    setModalImportOpened(true);
  };

  const confirmDelete = () => {
    setModalDeleteOpened(true);
  };

  const importMe = async () => {
    try {
      setImporting(true);
      const tempData = JSON.parse(JSON.stringify(dataResult.eventData));
      if (
        tempData.codigoEventoAudiencia === AudienciaCodigo.Financeiro &&
        (tempData.codigoEventoTipo === TipoCodigo.Coleta ||
          tempData.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
          tempData.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar)
      ) {
        tempData.codigoEventoAudiencia = AudienciaCodigo.Operacional;
      }
      tempData.codigoEventoStatus = Status.Pendente;
      delete tempData.resumoJSON?.veiculoCompartilhado;

      sessionStorage.setItem(SessionStorageKey.TempEvent, JSON.stringify(tempData));
      props.callback(
        [{ ...buildFakeAuditObject(), ...dataResult.eventData! }],
        'callback-importar',
        null,
        true
      );
    } catch (error: any) {
      showNotification({
        title: `Evento - Visualizar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível excluír o evento.',
        color: 'red',
      });
    } finally {
      setImporting(false);
    }
  };

  const deleteMe = async () => {
    try {
      setDeleting(true);
      await eventsService.delete({ idEvento: props.idEvento });
      showNotification({
        title: `Evento - Visualizar`,
        message: `Evento ${props.idEvento} excluído com sucesso.`,
        color: 'green',
      });

      props.callback(
        [{ ...buildFakeAuditObject(), ...dataResult.eventData! }],
        'callback-remover',
        null,
        true
      );
    } catch (error: any) {
      showNotification({
        title: `Evento - Visualizar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível excluír o evento.',
        color: 'red',
      });
    } finally {
      setDeleting(false);
    }
  };

  // const confirmPrint = () => {
  //   setModalPrintOpened(true);
  // };

  const printMe = async () => {
    try {
      window.open(`${Feature.Home.Event.link}/${dataResult.eventData!.idEvento}/print`, '_blank')!;
      showNotification({
        title: `Evento - Visualizar`,
        message: `Evento ${props.idEvento} enviado para impressão com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: `Evento - Visualizar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível imprimir o evento.',
        color: 'red',
      });
    }
  };

  const confirmStatus = (newStatusCode: Status) => {
    setModalStatusData({ opened: true, status: newStatusCode });
  };

  const updateStatus = async (newStatusCode: Status) => {
    const evento = dataResult.eventData!;
    const statusData = refStatusForm.current?.validate();

    try {
      if (newStatusCode !== Status.Cancelado) {
        if (!evento.idResponsavel) {
          showNotification({
            title: `Evento - Visualizar`,
            message: 'É necessário definir um responsável antes de alterar o evento para esse status.',
            color: 'red',
          });
          return;
        }
      }

      if (newStatusCode === Status.Finalizado) {
        if (evento.codigoEventoStatus === Status.PendenteMedicao && !evento.medicao) {
          showNotification({
            title: `Evento - Visualizar`,
            message: 'É necessário definir a medição antes de alterar o evento para esse status.',
            color: 'red',
          });
          return;
        }
      }

      if (newStatusCode === Status.EmRevisao) {
        if (evento.codigoEventoStatus === Status.Pendente && !evento.faturamento) {
          showNotification({
            title: `Evento - Visualizar`,
            message: 'É necessário definir o faturamento antes de alterar o evento para esse status.',
            color: 'red',
          });
          return;
        }
      }

      if (newStatusCode === Status.PendenteMedicao) {
        if (evento.codigoEventoStatus === Status.Finalizado) {
          if (evento.faturado) {
            showNotification({
              title: `Evento - Visualizar`,
              message: 'Não é possível alterar o status desse evento pois já está faturado.',
              color: 'red',
            });
            return;
          }

          if (evento.relacoes.findIndex((x) => x.codigoEventoRelacao === Relacao.Cobranca) > -1) {
            showNotification({
              title: `Evento - Visualizar`,
              message: 'Não é possível alterar o status desse evento pois faz parte de um fechamento ativo.',
              color: 'red',
            });
            return;
          }
        }
      }

      setUpdating(true);

      await eventsService.updateStatus({
        ...statusData,
        idEvento: evento.idEvento,
        codigoEventoStatus: newStatusCode,
      });

      const newEventStatus = props.referenceData.eventStatusData.find(
        (x) => x.codigoEventoStatus === newStatusCode
      )!.eventoStatus;

      setDataResult({
        ...dataResult,
        eventData: {
          ...dataResult.eventData!,
          codigoEventoAudiencia:
            newStatusCode === Status.PendenteMedicao
              ? AudienciaCodigo.Financeiro
              : dataResult.eventData!.codigoEventoAudiencia,
          eventoAudiencia:
            newStatusCode === Status.PendenteMedicao
              ? Audiencia.Financeiro
              : dataResult.eventData!.eventoAudiencia,
          codigoEventoStatus: newStatusCode,
          eventoStatus: newEventStatus,
          status: [
            ...dataResult.eventData!.status,
            {
              ...buildFakeAuditObject(),
              idEventoStatus: new Date().valueOf(),
              codigoEventoStatus: newStatusCode,
              eventoStatus: newEventStatus,
              codigoEventoStatusMotivo: statusData.codigoEventoStatusMotivo,
              eventoStatusMotivo: statusData.eventoStatusMotivo,
              observacao: statusData.observacao,
              idCriadoPor: currentUser.idUsuario,
              criadoPor: currentUser.nomeCompleto,
              dataCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
            },
          ],
          modificadoPor: currentUser.nomeCompleto,
          dataModificacao: moment().format('YYYY-MM-DD HH:mm:ss') as any,
        },
      });

      props.callback(
        [
          {
            ...buildFakeAuditObject(),
            ...dataResult.eventData!,
            codigoEventoStatus: newStatusCode,
            eventoStatus: newEventStatus,
          },
        ],
        'callback',
        null,
        true
      );

      if (
        (newStatusCode === Status.PendenteMedicao &&
          dataResult.eventData?.codigoEventoAudiencia !== AudienciaCodigo.Financeiro) ||
        (newStatusCode === Status.Pendente &&
          dataResult.eventData?.codigoEventoStatus === Status.PendenteMedicao &&
          dataResult.eventData?.codigoEventoAudiencia === AudienciaCodigo.Financeiro)
      ) {
        props.callback(
          [
            {
              ...buildFakeAuditObject(),
              ...dataResult.eventData!,
              codigoEventoStatus: newStatusCode,
              eventoStatus: newEventStatus,
              codigoEventoAudiencia:
                newStatusCode === Status.PendenteMedicao
                  ? AudienciaCodigo.Financeiro
                  : AudienciaCodigo.Operacional,
              eventoAudiencia:
                newStatusCode === Status.PendenteMedicao ? Audiencia.Financeiro : Audiencia.Operacional,
              idResponsavel: null,
              responsavel: null,
            },
          ],
          'callback-remover',
          null,
          true
        );
      }

      showNotification({
        title: 'Evento - Visualizar',
        message: `Status do evento alterado com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: `Evento - Visualizar`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível alterar o status do evento.',
        color: 'red',
      });
    } finally {
      setUpdating(false);
    }
  };

  const buildStatusButton = () => {
    let statuses: Status[] = [];
    if (dataResult.eventData?.codigoEventoStatus) {
      statuses = getValidStatuses(
        dataResult.eventData.codigoEventoTipo as TipoCodigo,
        dataResult.eventData.codigoEventoStatus
      );
    }
    if (statuses.length === 0) {
      return <Button hidden />;
    }

    const menuItems: ReactNode[] = [];

    for (const status of statuses.filter((x) => x !== Status.Cancelado)) {
      const statusAction = getStatusAction(status);
      menuItems.push(
        <Menu.Item
          key={statusAction.action}
          icon={<statusAction.icon size={14} />}
          onClick={() => {
            confirmStatus(status);
          }}
        >
          {statusAction.action}
        </Menu.Item>
      );
    }

    if (statuses.find((x) => x === Status.Cancelado)) {
      const statusAction = getStatusAction(Status.Cancelado);
      menuItems.push(
        <div key={statusAction.action}>
          <Divider />
          <Menu.Item
            key={statusAction.action}
            color="red"
            icon={<statusAction.icon size={14} />}
            onClick={() => {
              confirmStatus(Status.Cancelado);
            }}
          >
            {statusAction.action}
          </Menu.Item>
        </div>
      );
    }

    return (
      <Menu shadow="md" withArrow>
        <Menu.Target>
          <Button
            color="orange"
            leftIcon={<Switch2 size={18} />}
            loading={updating}
            disabled={updating || deleting || dataResult.loading}
          >
            Status
          </Button>
        </Menu.Target>

        <Menu.Dropdown style={{ minWidth: 250 }}>{menuItems.map((x) => x)}</Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <div>
      {dataResult.loading ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <div>
          <Modal
            opened={modalImportOpened}
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={() => setModalImportOpened(false)}
            title="Gostaria de importar esse evento?"
          >
            <Group position="right">
              <Button
                color="secondary"
                onClick={() => {
                  setModalImportOpened(false);
                }}
              >
                Não
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  setModalImportOpened(false);
                  await importMe();
                }}
              >
                Sim
              </Button>
            </Group>
          </Modal>

          <Modal
            opened={modalDeleteOpened}
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={() => setModalDeleteOpened(false)}
            title="Gostaria de excluir esse evento?"
          >
            <Group position="right">
              <Button
                color="secondary"
                onClick={() => {
                  setModalDeleteOpened(false);
                }}
              >
                Não
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  setModalDeleteOpened(false);
                  await deleteMe();
                }}
              >
                Sim
              </Button>
            </Group>
          </Modal>

          <Modal
            opened={modalPrintOpened}
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={() => setModalPrintOpened(false)}
            title="Gostaria de imprimir esse evento?"
          >
            <Group position="right">
              <Button
                color="secondary"
                onClick={() => {
                  setModalPrintOpened(false);
                }}
              >
                Não
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  setModalPrintOpened(false);
                  await printMe();
                }}
              >
                Sim
              </Button>
            </Group>
          </Modal>

          {modalStatusData.opened && (
            <Modal
              opened={modalStatusData.opened}
              closeOnClickOutside={false}
              closeOnEscape={false}
              onClose={() => setModalStatusData({ opened: false, status: null })}
              title="Confirma a mudança de status?"
              size="lg"
            >
              <Paper shadow="xs" p="md" withBorder>
                <SimpleGrid cols={2}>
                  <PageViewProperty
                    label="Status Atual"
                    text={dataResult.eventData!.eventoStatus}
                    size="sm"
                  />
                  <PageViewProperty
                    label="Novo Status"
                    // text={getStatusAction(modalStatusData.status!).action}
                    text={
                      props.referenceData.eventStatusData.find(
                        (x) => x.codigoEventoStatus === modalStatusData.status
                      )!.eventoStatus
                    }
                    size="sm"
                  />
                </SimpleGrid>
                <Space h="md" />

                <StatusChangeForm
                  ref={refStatusForm}
                  event={dataResult.eventData!}
                  newStatusCode={modalStatusData.status!}
                  eventStatusReasonData={props.referenceData.eventStatusReasonData}
                />
              </Paper>
              <Space h="lg" />

              <Group position="right">
                <Button
                  color="secondary"
                  onClick={() => {
                    setModalStatusData({ opened: false, status: null });
                  }}
                >
                  Não
                </Button>
                <Button
                  color="primary"
                  onClick={async () => {
                    const statusData = refStatusForm.current?.validate();
                    if (statusData === null) {
                      return;
                    }

                    const tempStatus = `${modalStatusData.status}`;
                    setModalStatusData({ opened: false, status: null });
                    await updateStatus(tempStatus as Status);
                  }}
                >
                  Sim
                </Button>
              </Group>
            </Modal>
          )}

          <Paper shadow="xs" p="md" withBorder>
            {(dataResult?.eventData?.relacoes || [])?.findIndex((x) =>
              [Relacao.Substituicao, Relacao.Juncao].includes(x.codigoEventoRelacao)
            ) > -1 && (
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                Esse evento está relacionado à outro evento de substituição/junção.
              </Alert>
            )}

            <Tabs
              items={tabs.filter((x) => !x.disabled) || []}
              defaultActiveKey="Geral"
              className={`custom-ant-tabs-nav-list custom-ant-tabs-nav-list-${
                tabs.filter((x) => !x.disabled).length
              }`}
            />
          </Paper>
          <Space h="lg" />

          <Group position="apart">
            <Group>
              {dataResult.eventData!.codigoEventoTipo !== TipoCodigo.Expiracao &&
                dataResult.eventData!.codigoEventoTipo !== TipoCodigo.Cobranca &&
                dataResult.eventData!.codigoEventoTipo !== TipoCodigo.Comissao &&
                !dataResult.eventData!.obsoleto &&
                validate(Permission.EventAdd, currentUser.permissoes) && (
                  <Button
                    color="gray"
                    leftIcon={<FileImport size={18} />}
                    onClick={confirmImport}
                    loading={importing}
                    disabled={importing || deleting || updating || dataResult.loading}
                  >
                    Importar
                  </Button>
                )}
            </Group>
            <Group>
              {/* <Button
              color={Feature.Home.Event.color}
              leftIcon={<Printer size={18} />}
              onClick={() => {
                confirmPrint();
              }}
              disabled={importing || deleting || updating || dataResult.loading}
              loading={updating}
            >
              Imprimir
            </Button> */}
              {dataResult.eventData!.codigoEventoStatus !== Status.Cancelado &&
                dataResult.eventData!.codigoEventoStatus !== Status.Finalizado &&
                validate(Permission.EventEdit, currentUser.permissoes) && (
                  <Button
                    color="accent"
                    leftIcon={<Edit size={18} />}
                    onClick={() =>
                      props.callback(
                        [{ ...buildFakeAuditObject(), ...dataResult.eventData! }],
                        'editar',
                        null,
                        true
                      )
                    }
                    loading={deleting}
                    disabled={importing || deleting || updating || dataResult.loading}
                  >
                    Editar
                  </Button>
                )}
              {validate(Permission.EventEdit, currentUser.permissoes) && buildStatusButton()}
              <Button
                color="red"
                leftIcon={<Trash size={18} />}
                onClick={confirmDelete}
                loading={deleting}
                disabled={importing || deleting || updating || dataResult.loading}
                hidden={
                  dataResult.eventData?.codigoEventoStatus === Status.Finalizado &&
                  (dataResult.eventData?.codigoEventoTipo === TipoCodigo.Cobranca ||
                    dataResult.eventData?.codigoEventoTipo === TipoCodigo.Coleta ||
                    dataResult.eventData?.codigoEventoTipo === TipoCodigo.Comissao ||
                    dataResult.eventData?.codigoEventoTipo === TipoCodigo.Fechamento ||
                    dataResult.eventData?.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
                    dataResult.eventData?.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar) &&
                  validate(Permission.EventDelete, currentUser.permissoes)
                }
              >
                Excluir
              </Button>
            </Group>
          </Group>
        </div>
      )}
    </div>
  );
}
